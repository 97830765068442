import path from '@/api/path'
import service from '@/api/service'

const { resource } = path

export default {
  // 我的资源
  list: (data) => {
    return service.post(resource.list, data)
  }
}
