<template>
  <div class="protocol h-752px">
    <div
      class="main overflow-hidden px-53px bg-white text-19px flex flex-col"
      style="color: #333"
    >
      <div class="h-full overflow-y-auto" v-html="html"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      html: ''
    }
  },
  created() {
    this.getHtml()
  },
  mounted() {},
  methods: {
    getHtml() {
      this.html = `<div style="font-size:14px;">
	<span style="font-weight:bold;">始智AI/wisemodel用户协议与隐私政策</span>
</div>
<div style="font-size:14px;">
	更新日期：&nbsp;2023&nbsp;年&nbsp;05月&nbsp;20&nbsp;日
</div>
<div style="font-size:14px;">
	生效日期：&nbsp;2023&nbsp;年&nbsp;05&nbsp;月20&nbsp;日
</div>
<div id="g19F-1684208941469" style="font-size:14px;">
	<span style="font-weight:bold;font-size:28px;">用户协议</span>
</div>
<div style="font-size:14px;">
	欢迎您来到始智AI/wisemodel平台。始智AI/wisemodel平台（下称“本平台”）旨在打造新一代AI基础设施服务平台，构建AI技术开源创新平台，为AI产业发展提供有力的平台支撑，让AI技术、模型、数据集等更容易获取和更方便使用，让AI更简单。
</div>
<div style="font-size:14px;">
	大家的使用体验对我们非常重要，为帮助大家更好了解本平台内容以及明晰用户的使用权责，北京始智科技有限公司（下称“始智AI”或“我们”）制定本《始智AI/wisemodel平台用户协议》（下称“本协议”）并提醒您：<span style="font-weight:bold;">请您务必仔细阅读并透彻理解本协议，在确认充分理解并同意后方使用本平台，尤其是责任提示条款，我们将以加粗形式提醒您特别注意。一旦您收到本协议并开始使用本平台，将被视为对本协议内容的接受和认可，本协议立即生效。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，请您立即停止使用本平台。</span>&nbsp;
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">我们十分重视公民的个人隐私和信息安全，对此我们专门制定了 始智AI/wisemodel平台隐私政策&nbsp;进行特别保护，请您认真阅读该政策。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">若您是18周岁以下的未成年人，建议您请您的监护人仔细阅读本协议（特别是“六、未成年人使用条款”），并在征得您的监护人同意的前提下使用本平台或向我们提供信息。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;"></span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">一、本协议的适用范围、效力及变动</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			本协议仅适用于您在本平台上的数据集、模型上传与下载，以及所有本平台提供的功能和服务等，<span style="font-weight:bold;">不适用于第三方向您提供的服务，您接受第三方服务时应另行遵守第三方的用户协议与隐私政策或类似文件，我们对任何第三方不当服务或违规行为不承担任何法律责任。</span>
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			针对您在使用本平台过程中的个人信息处理和保护之问题，请您另行阅读我们制定的&nbsp;<span style="font-weight:bold;">《隐私政策》</span>。
		</li>
		<li style="font-size:14px;text-align:left;font-weight:bold;font-family:&quot;">
			本协议一经公布即生效，我们有权根据法律法规及政策变动、公司经营策略调整和产品服务升级等原因随时对本协议进行修订或重立。更新后的协议条款公布后即有效代替原来的协议条款，您可随时在本平台查阅最新版协议条款。如果您继续使用本平台，即表示接受更新后的条款。如果您不同意协议变动，请您立即停止使用本平台。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			本协议中条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			本协议中的所有条款均具有独立的法律效力，部分条款完全或部分无效或缺乏执行力时，不影响其他条款的有效性和可执行力。
		</li>
		<li style="font-size:14px;text-align:left;font-weight:bold;font-family:&quot;">
			因不同的功能需求，我们可能会在具体场景下增设附加条款，包括但不限于平台公约、服务要求（包括年龄要求）、特定功能下的单独协议。附加条款将会与相关功能一同提供，在您使用这些功能后，即成为本协议一部分并立即生效。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			本协议的签订地为中华人民共和国北京市海淀区。&nbsp;
		</li>
	</ol>
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">二、用户行为规范及责任</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（一）账号注册</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			我们提供的各种服务和功能，您需注册登录账号后方可使用。账号注册过程中，您需同意《用户协议》、《隐私政策》的条款并按照页面上的提示完成注册。您注册成功后，我们将给予您一个使用本平台的用户账户及相应的密码，该用户账号和密码由您自己负责保管。您可以通过账号和密码或采用手机号短信验证码的方式登录，请您保管好验证码；<span style="font-weight:bold;">您应当对以个人用户账号实施的所有行为负法律责任。</span>&nbsp;
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			为使您更好地使用平台，保障您的账号安全，&nbsp;某些功能和/或某些单项服务项目，按照国家相关法律法规及政策规定，可能需要您提供真实的身份信息实名认证后方可使用。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			我们有权对您提供的资料进行合理审查，<span style="font-weight:bold;">若您提供的资料、信息或证件包含有不正确、不真实的信息，我们有权拒绝为您提供服务或单方终止已向您提供的服务。</span>
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（二）&nbsp;平台使用规范</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			为确保本平台不被用于网络违法意图对网络生态产生负面影响，您承诺，未经相关权利人允许，不得针对或利用本平台直接或间接实施以下行为：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）侵入计算机信息系统；&nbsp;
</div>
<div style="font-size:14px;">
	（2）采用技术手段，获取计算机信息系统中存储、处理或者传输的数据；
</div>
<div style="font-size:14px;">
	（3）对计算机信息系统实施控制；
</div>
<div style="font-size:14px;">
	（4）对计算机信息系统功能进行删除、修改、增加、干扰；&nbsp;
</div>
<div style="font-size:14px;">
	（5）对计算机信息系统中存储、处理或者传输的数据和应用程序进行删除、修改、增加；&nbsp;
</div>
<div style="font-size:14px;">
	（6）故意制作、传播计算机病毒等破坏性程序；&nbsp;
</div>
<div style="font-size:14px;">
	（7）其他危害计算机信息网络安全或对互联网络正常运转产生负面影响的行为。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			为保障本平台安全、稳定、正常运行，您保证，未经我们允许，禁止直接或间接实施以下行为：&nbsp;
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）突破或避开本平台中的任何安全防护网、内容保护机制或数据度量工具等；&nbsp;
</div>
<div style="font-size:14px;">
	（2）以任何方式影响我们在本平台中的数据处理、信息管理等权利；
</div>
<div style="font-size:14px;">
	（3）通过反编译、逆向工程或以其他方式尝试获取本平台的源代码或后端服务的信息；
</div>
<div style="font-size:14px;">
	（4）其他干扰或企图干扰本平台正常运行的行为。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			为保证用户在使用本平台过程中的个人账号安全等合法权益，您承诺，未经我们允许或相关用户同意，禁止直接或间接实施以下行为：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）自行导出其他用户在本平台上的信息；
</div>
<div style="font-size:14px;">
	（2）向其他非原始用户显示、披露或提供其他原始用户的信息；&nbsp;
</div>
<div style="font-size:14px;">
	（3）索要、窃取或以其他方式获取其他用户的账号、密码或其他身份验证信息；&nbsp;
</div>
<div style="font-size:14px;">
	（4）未经授权冒充他人获取对本平台的访问权；
</div>
<div style="font-size:14px;">
	（5）其他侵犯或企图侵犯用户正常使用本平台的行为。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			本平台部分模型和功能涉及深度合成技术&nbsp;<span style="font-weight:bold;">（我们将在具体页面提供显著标识）</span>&nbsp;，我们依法针对您使用相关模型和功能制作的信息内容制定如下规范：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）本协议所述制作的信息内容是指您使用本平台过程中所上传、复制、生成、存储、发布、传播的所有内容，包括但不限于账户头像、名称等账户信息以及您输入、回复、生成的文字、语音、图片、视频等信息内容。&nbsp;
</div>
<div style="font-size:14px;">
	（2）您在本平台上的全部行为应符合法律法规及政策、社会公共秩序和道德风尚，如禁止反动、色情、暴力、冒犯、侮辱等不良信息内容的输入和生成。<span style="font-weight:bold;">我们会依法采取技术或者人工方式对您的输入数据和合成结果进行审核，您注册账号下的一切行为由您自行完全负责。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（3）您确保，您使用本平台制作的信息内容，是您所有或被相关权利人授权同意处理的信息，我们根据您的指令分析、处理该等信息内容以及生成新的信息内容不会侵犯任何第三方的合法权益（包括但不限于著作权、名誉权、财产权）。当您在本平台上的信息内容侵犯了第三方的合法权益而导致我们或/及我们的合作方遭受任何投诉、举报、索赔或诉讼，您有义务积极采取措施（包括但不限于积极举证回应、参与诉讼），以保证我们和我们的合作方免受或尽快摆脱该困境。当我们或/及我们的合作方因此遭受任何名誉、声誉或财产上的直接或间接损失时，您负有全部的损害赔偿责任。</span>&nbsp;
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（4）您不得利用本平台编造、传播虚假信息。您在发布或传播利用本平台制作生成的非真实信息时，&nbsp;依法应当以显著方式予以标识（包括但不限于在生成图片中添加水印、附上非真实信息声明）。如您非法利用本平台编造、传播虚假信息的，我们会依法保存有关记录，并向网信部门和有关主管部门报告。</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			对于您发布、上传、链接或通过服务提供的任何内容，无论内容的形式如何，您应对您所上传的内容承担责任。在法律允许的范围内，我们对您创建或上传的内容任何公开显示或使用不承担责任，但我们有权删除我们或有权的监管机构认为违反了任何法律或平台协议或政策的内容。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			若您在本平台所上传的模型涉及深度合成技术或利用相关模型自行开发应用、对外提供服务等，我们特别提醒您，您将作为技术支持者或服务提供者，您应严格遵守《中华人民共和国网络安全法》《中华人民共和国数据安全法》《中华人民共和国个人信息保护法》《互联网信息服务管理办法》《互联网信息服务深度合成管理规定》等法律、行政法规及规章制度，我们不承担相关模型所引发的任何法律责任和风险。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您违反本上述约定时，我们有权就具体情节，对您做出警告、限制功能、暂停服务、封禁账号甚至关闭账户的处罚并公告处理结果，对于危害国家安全、公共安全和国防利益的行为，我们将进行严厉处理；如因您违反上述规范而致使其他用户、我们或我们的任何合作方权益受损，您需自行完全承担一切法律责任，严重时您将可能承担刑事责任。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">三、知识产权及相关权益 </span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			对于本平台提供的服务、功能和内容，包括但不限于软件、代码、模型、数据、文件、更新、商号、商标、服务标记、标识、域名（统称为“始智AI知识产权”），我们拥有对始智AI知识产权及其中所含的全部权利和所有权或相关授权。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			对于用户利用本平台部分模型和功能的AI合成技术生成的文字、图片、视频等内容，其所有权和相关权益由我们和法定权利人所有。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您承诺遵守和保护您上传、访问或获取内容中所包含的知识产权。在未经该内容相关权益人的事先同意,&nbsp;您不会使用、复制、修改、翻译、发布、广播、传输、分发、出售或以其他方式利用任何非您拥有的内容，并且您不会尝试规避防止未经授权复制或分发内容的任何机制，否则本网站或内容发布者可能停止您访问该内容，也可能追究您的侵权责任。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您可根据本平台要求，您上传的模型、数据集以及在其他在平台上发布和分享的内容信息，您理解并承诺：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（1）按照页面要求如实填写所上传模型和数据集的信息（包括但不限于所有人、License、公开情况），对您所上传的模型和数据集，您已获得该模型和数据集的所有权人和其他权利人许可，遵守了开源协议，您的上传行为不会侵犯任何主体的合法权益。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（2）您所上传的模型所使用的训练和数据集均已遵守了开源协议，或获得原所有权人和其他权利人许可，该数据集用以您上传模型的训练和开发不存在不正当竞争等侵犯任何主体合法权益的情形。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（3）您在体验本平台部分模型和功能时所输入的文字、图片、音频、视频等内容信息，均已获得了原所有权人和其他权利人同意，您的输入行为不会侵犯任何主体的合法权益。</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			作为网站的贡献者，针对您向网站提交的任何内容，由于第三方可能对该内容进行免费下载及其他各种方式的使用，您在此授予本网站永久性的、全球范围内的、非排他的、无须许可费的和不可撤销的知识产权许可与分发许可权，以复制、展示、执行、转授权、发布或其他任何方式使用、修改您的贡献内容和该等衍生作品。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			如果任何实体针对您或其他实体提起知识产权诉讼（包括诉讼中的交叉请求或反诉），主张您的贡献内容（或您参与贡献的作品）造成了直接性或辅助性的侵权，则任何根据本协议针对该贡献内容或作品授予该实体的许可应当在起诉之日终止。您保证您依法有权授予上述许可。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			任何未经我们书面同意及权利人许可的获取行为，均属违法侵权行为。为及时、有效地保障您基于本平台的合法权益，您同意特别授权我们在发现您的合法权益&nbsp;（包括但不限于信息网络传播权、著作权等）可能受到侵害时，我们有权以自己的名义或委托专业第三方机构代您向涉嫌侵权的第三人采取法律手段进行维权，特别授权采取的维权手段包括但不限于侵权监控、&nbsp;发函警告、行政举报、提起诉讼、申请仲裁、移送侦查机关处理、调解、和解等。同时，请您注意，您对我们的上述授权，并不意味着我们与您之间存在任何利益共享机制。&nbsp;
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您违反上述约定引发的风险和法律责任，需由您自行完全承担。我们不就由您上述行为导致或间接导致的任何损害进行赔偿，无需向其他用户或任何第三方负法律责任。当您的行为侵犯我们的合法权益时，我们有权请求您承担法律责任。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">四、权益维护</span>
</div>
<div style="font-size:14px;">
	（一）举报侵权人的通知程序
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			通知内容
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	我们非常重视知识产权保护，如果您认为有用户利用本平台侵害您合法权益，且本平台上的或由平台链接的材料侵犯了您的知识产权，请您按照法律规定及时以书面方式向我们发送举报侵权的通知，该通知应至少包含以下内容：
</div>
<div style="font-size:14px;">
	（1）权利人的真实身份信息，以便我们能够及时联系您，具体包括：权利人姓名（或名称）、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址和联系方式（如电话号码、传真或电子邮件）；如您系受权利人授权委托处理该事宜，您还需提交您本人的真实身份信息（同权利人要求）以及授权委托材料，授权委托材料中请明确具体的授权内容。
</div>
<div style="font-size:14px;">
	（2）权利人享有权利的权属证明材料，以便我们防止他人冒充，滥用您的权利。请您明确您主张被侵权的版权作品（或商标），如果举报涉及多个作品（或商标），请提供详细清单，并附上您对其享有权利的证明材料。
</div>
<div style="font-size:14px;">
	（3）权利人要求采取必要措施的网络地址或者足以准确定位侵权内容的相关信息，以便我们能够尽快与涉嫌侵权方取得联系。如提供您举报的侵权内容的网络链接及其所在位置等。
</div>
<div style="font-size:14px;">
	（4）权利人主张侵权的理由和初步证据，以便我们能够针对您的诉求妥善处理。请您充分准确说明您的权益如何受损，并请提供足以表明对方侵权的证明材料。
</div>
<div style="font-size:14px;">
	（5）内容真实性声明，以便我们有效屏蔽虚假投诉，高效甄别您的真实投诉内容。请您在权利通知中加入如下声明并签名或盖章："本人保证，本通知中所述信息是充分、真实、准确的，我是所投诉内容的合法权利人，或，我已获授权，有权行使第（4）项中列明内容的权益。本人确认，在我举报的平台上可获取的内容侵犯了本人相应的合法权益。本人承诺，因该通知内容不实所引发的法律责任，由本人自行完全承担。”
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">我们提醒您特别注意：依照法律规定，如果通知中的的内容不完全属实导致他人（包含我们在内）权益受损，权利通知提交者将承担由此引发的全部法律责任（包括但不限于赔偿他人损失的费用及律师费）。如果您尚不确定平台上可获取的内容是否侵犯了您的合法权益，我们建议您先咨询专业人士，以避免造成您财产等不必要损失。</span>&nbsp;
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			举报侵权通知的送达
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	请您将以上材料书面扫描电子版发送到邮箱：contact@wisemodel.cn
</div>
<div style="font-size:14px;">
	（二）被举报侵权人的反通知程序
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			反通知内容
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	根据法律规定，我们在收到举报侵权人发出的侵权通知后，将会向被举报侵权人转送该通知并根据情况采取移除相关内容等必要措施，如您在收到该通知后发现举报内容不实，您的合法权益因此受损的，您有权向我们发送您不构成侵权的反通知，我们将依法及时处理。该反通知应至少包含以下内容：
</div>
<div style="font-size:14px;">
	（1）被举报人的真实身份信息，以便我们能够及时联系您，具体包括：被举报人姓名（或名称）、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址和联系方式（如电话号码、传真或电子邮件）；如您系受被举报人授权委托处理该事宜，您还需提交您本人的真实身份信息（同被举报人要求）以及授权委托材料，授权委托材料中请明确具体的授权内容。
</div>
<div style="font-size:14px;">
	（2）被举报人要求取消必要措施的网络地址或者足以准确定位被举报侵权内容的相关信息，以便我们能够尽快与举报人取得联系。如提供您被举报侵权内容的网络链接及其所在位置等。
</div>
<div style="font-size:14px;">
	（3）被举报人主张不构成侵权的理由和初步证据，以便我们能够针对您的诉求妥善处理。请您充分准确说明举报人的侵权举报不准确的理由，并请提供足以表明您不构成侵权的证明材料，如您对被举报内容享有的权属证明等。
</div>
<div style="font-size:14px;">
	（4）内容真实性声明，以便我们有效屏蔽虚假维权，高效甄别您的真实维权内容。请您在反通知中加入如下声明并签名或盖章："本人保证，本反通知中所述信息是充分、真实、准确的，我是被投诉侵权内容的合法权利人，或，我已获授权，有权行使第（3）、（4）项中列明内容的权益。本人确认，我被投诉举报的平台上可获取的内容未侵犯他人合法权益。本人承诺，因该反通知内容不实所引发的法律责任，由本人自行完全承担。”
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">我们提醒您特别注意：依照法律规定，如果反通知中的的内容不完全属实导致他人（包含我们在内）权益受损，反通知提交者将承担由此引发的全部法律责任（包括但不限于赔偿他人损失的费用及律师费）。如果您尚不确定您是否侵权，我们建议您先咨询专业人士，以避免造成您财产等不必要损失。</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			反通知的送达
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	请您将以上材料书面扫描电子版发送到邮箱：contact@wisemodel.cn
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">四、第三方软件或技术</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			本平台可能会使用第三方软件或技术（包括可能使用的开源代码和公共领域代码等，下同），该使用已经获得合法授权，或遵守相应的开源协议。&nbsp;
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			本平台如果使用了第三方的软件或技术，我们将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包、或通过开源软件页面等形式进行展示，&nbsp;它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。通过前述各种形式展现的相关协议、其他文件及网页，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求我们给予协助，您应当自行承担法律责任。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			如因本平台使用的第三方软件或技术引发的任何纠纷，应当由该第三方负责解决，我们不承担任何责任。我们不对第三方软件或技术提供客服支持，若您需要获取支持，请与第三方联系。&nbsp;
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">五、出口管制条款</span>
</div>
<div style="font-size:14px;">
	本平台可能受中国或其他国家或地区的出口管制或限制。您同意，在使用本平台过程中遵守中国和国际出口法律法规。这些法律可能包括对目的地、内容和/或最终用户的限制。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">六、未成年人使用条款</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			&nbsp;若您是未满18周岁的未成年人，您应获得监护人同意后在其监护下，认真阅读并同意本协议后，方可使用本平台。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			未成年用户在填写个人信息时，请增强保护意识并谨慎对待，并应在监护人指导下进行。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			未成年人用户及其监护人理解并确认，如您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			为更好地保护未成年人隐私权益，我们特别提醒您慎重上传、发布包含未成年人素材的信息内容，&nbsp;一经上传和发布，即视为您已获得相关权利人同意，且允许我们依据本协议使用、处理该信息内容。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			监护人特别提示
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）您应依法监督指导您的被监护人使用安全合理使用本平台，防范其接触危害或者可能影响其身心健康的网络信息，预防和干预您的被监护人沉迷网络。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（2）如您的被监护人使用本平台的，您作为监护人应指导并监督被监护人的注册和使用行为，如您的被监护人申请注册平台账号，我们将有权认为其已取得您的同意。</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			如您在使用本平台过程中发现任何可能影响未成年人身心健康的信息内容或者对未成年人实施侮辱、诽谤、威胁或者恶意损害形象等网络欺凌行为的，请您通过“八、举报和投诉渠道”向我们反映该情况，我们将尽快处理并反馈。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">七、免责声明</span>
</div>
<div style="font-size:14px;">
	为充分保证您在使用本平台过程中的知情权等合法权益，我们将特别提醒您可能对您产生重要影响的情形。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			当您出现以下情形时，我们有权视具体情况中止或终止向您提供本平台的服务，由此造成的损失需由您自行完全承担：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）违反法律、法规、政策及一切具有法律效力的规范；
</div>
<div style="font-size:14px;">
	（2）违反本协议规定；
</div>
<div style="font-size:14px;">
	（3）影响其他用户体验；
</div>
<div style="font-size:14px;">
	（4）存在安全隐患；
</div>
<div style="font-size:14px;">
	（5）违背我们的运营原则，或不符合我们其他管理要求。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			因现阶段科学技术的局限性和生成式人工智能的特殊性，我们不能保证（包括但不限于）：&nbsp;
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）本平台上的数据集、模型等满足您的使用要求和目的；
</div>
<div style="font-size:14px;">
	（2）您经由本平台取得的任何产品、服务或其他材料符合您的期望；
</div>
<div style="font-size:14px;">
	（3）本平台的稳定性、正常运行时间及可靠性，<span style="font-weight:bold;font-style:italic;">请不要对本平台和本平台中的所有信息内容产生任何依赖，我们将不对您或您的下游客户因您对本平台的依赖而造成的任何损害负责</span><span style="font-style:italic;">；</span>
</div>
<div style="font-size:14px;">
	（4）生成内容合规、准确和完整，且&nbsp;<span style="font-weight:bold;">本平台上的模型由用户直接或间接所生成内容并不代表我们的态度和观点。</span>&nbsp;生成式模型生成的内容仍不排除其中部分信息具有错误或会产生负面影响。如遇此情形，欢迎并感谢您按照本协议“八、举报和投诉渠道”释明的路径或者直接在模型的交流讨论区进行反映，我们将尽快联系模型提供方进行处理。&nbsp;
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			鉴于网络技术的特殊性，对于本平台变动风险，您理解并同意：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）我们有权基于任何理由随时变更、中断或终止部分或全部的网络功能。如变更、中断或终止的网络功能免费的，我们无需通知您。一旦功能取消，您使用本平台的权利立即终止，您在本平台中储存的任何信息可能无法恢复，我们无需对您或任何第三方承担任何责任。&nbsp;
</div>
<div style="font-size:14px;">
	（2）我们在定期或不定期进行系统及服务器配置、维护、升级时，需要短时间中断功能，我们无需为此承担任何责任，但会尽可能事先进行通告。&nbsp;
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			基于以下原因而造成的利润、商业信誉、资料损失及其他有形或无形损失，我们无需进行任何直接、间接的赔偿或补偿：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）使用或无法使用本平台；
</div>
<div style="font-size:14px;">
	（2）使用经由本平台取得的任何产品、资料或服务；
</div>
<div style="font-size:14px;">
	（3）您的信息内容遭到第三方未授权的使用或修改。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			第三方产品和服务风险
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（1）您在使用本平台中由第三方提供的产品或服务时，请您自觉遵守第三方的用户协议和使用规范。我们不对您与第三方提供的产品或服务可能出现的纠纷承担责任。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（2）您在使用本平台访问第三方网站时，因第三方网站及相关内容所可能导致的风险，或由于您授权第三方（包括第三方应用）访问/使用本平台所导致的纠纷或损失风险，需由您自行完全承担。</span>
</div>
<div style="font-size:14px;">
	（3）在您使用本平台或要求我们提供特定服务时，我们可能会借用第三方平台或者通过第三方系统支持您的使用或访问，该使用或访问的结果由该第三方提供，<span style="font-weight:bold;">我们不保证通过第三方所提供服务及内容的安全性、准确性、功能性等，我们不对由此引发的任何损害承担责任。</span>&nbsp;
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			根据法律规定，对于不受我们控制的其他风险，需由您自行完全承担，包括但不限于：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）由于不可抗力或者其他意外事件引起的数据丢失、泄漏等风险；
</div>
<div style="font-size:14px;">
	（2）由于本平台与您的终端设备型号不相匹配，或您使用的网络信号差、带宽小等原因所导致的损害风险；
</div>
<div style="font-size:14px;">
	（3）您的信息内容被他人盗用，或经传播后所引发的风险；
</div>
<div style="font-size:14px;">
	（4）其他因不受我们控制因素所产生的任何风险。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			如因您违反有关法律法规或者本协议之规定，使我们遭受任何损失、受到其他用户、任何第三方的索赔或任何行政管理部门的处罚，您应对我们、其他用户或相关第三方的实际损失进行全额赔偿，包括合理的律师费用。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您理解并同意，如果我们未执行或未行使包含在本协议中的（或我们在任何适用法律下有权享受的）任何法定权利或赔偿，不可视为对我们的权利的正式自动放弃，这些权利或救济仍对我们有效。我们于您过失或违约时放弃本协议规定的权利，也不视为其对您的其他或以后同类之过失或违约行为弃权。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">八、举报和投诉渠道</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			当出现以下情形时，任何人均有权向我们进行反映，我们将及时受理、处理和反馈处理结果：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）本平台上内容涉嫌违反法律法规及政策或违反本协议约定的；
</div>
<div style="font-size:14px;">
	（2）有用户在使用本平台过程中涉嫌违反法律法规及政策或违反本协议约定的，包括但不限于：
</div>
<div style="font-size:14px;">
	1) 危害国家安全、公共安全、国防利益的；
</div>
<div style="font-size:14px;">
	2) 侵犯他人知识产权、人格1权、财产权的；
</div>
<div style="font-size:14px;">
	3) 制作、复制、发布、传播虚假信息的；
</div>
<div style="font-size:14px;">
	4) 危害未成年人身心健康的；
</div>
<div style="font-size:14px;">
	（3）&nbsp;您认为有必要向我们反映的其他问题。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			反映渠道
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）联系邮箱：contact@wisemodel.cn
</div>
<div style="font-size:14px;">
	（2）微信公众号：始智AI-wisemodel.cn
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			如果我们收到他人举报或投诉后，确认您违反本协议约定的，我们有权不经通知随时对相关内容进行删除，&nbsp;并就具体情节，对您做出警告、限制功能、暂停服务、封禁账号甚至关闭账户的处罚并公告处理结果。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			当您的行为违反国家法律法规时，我们将依法保存有关信息并向有关部门报告，您应自行完成承担由此而产生的一切法律责任，严重时您将可能承担刑事责任。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">九、争议解决和适用法律</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">任何一方均可向北京市海淀区的人民法院提起诉讼。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;"></span>
</div>
<div id="VDhN-1684208941771" style="font-size:14px;">
	<span style="font-weight:bold;font-size:28px;">隐私政策</span>
</div>
<div style="font-size:14px;">
	您的信任对我们非常重要。我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴此，始智AI/wisemodel（下称“我们”或“本平台”）制定本《始智AI/wisemodel平台隐私政策》（下称“本政策”或“本隐私政策”）以帮助您充分了解，在您使用本平台的产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息。
</div>
<div style="font-size:14px;">
	在您开始使用我们的服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以&nbsp;<span style="font-weight:bold;">粗体/粗体下划线</span>&nbsp;标识的条款，确保您充分理解和同意后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;"></span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">一、我们如何收集和使用您的信息</span>
</div>
<div style="font-size:14px;">
	在您使用我们的服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			实现向您提供我们服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的服务；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			为实现向您提供我们服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们服务的基本功能。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	我们收集到您的信息将被用于以下目的：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			进行数据统计，以了解运营状况；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			分析用户对本网站的使用方式和使用习惯，以便改进我们的服务；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			阻止违法或潜在违法行为，阻止违反网站用户规范；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			我们在收集特定信息时向您披露的其他用途。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	我们在此特别提醒您：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			我们致力于打造多样的服务以满足您的需求。因我们向您提供的服务种类众多，且不同用户选择使用的具体服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的服务功能为准；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			为给您带来更好的产品和服务体验，我们在持续努力改进我们的服务及技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。如发生上述情形，我们将通过更新本政策、弹窗、页面提示等方式另行向您详细说明，并为您提供自主选择同意的方式，且在征得您明示同意后再开始收集、使用。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">在此过程中，如果您有任何疑问、意见或建议的，您可通过本政策文末联系方式与我们联系，我们会尽快为您作出解答。</span>
</div>
<div style="font-size:14px;">
	我们会为您提供的各项具体功能场景包括：
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（一）帮助您成为我们的用户及账户管理</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			基础服务
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	当您浏览本平台网站时（不管是否注册或登录），我们可能自动收集与您相关的如下信息：您的浏览器类型和浏览器语言、操作系统类型、宽带类型；您的IP(Internet&nbsp;Protocol)地址，该地址有时可以显示您所在地理位置；您在网站内浏览过的页面、广告和链接；用户行为日志；您访问网站时发出的访问请求及其日期、时间和引荐网址（如有）。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">为了确保我们是在为您本人提供服务，我们可能会根据您提供的上述信息校验您的身份。</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			账户管理
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	授权登录：我们可能经您同意后向第三方共享您的账户信息（头像、昵称及其他页面提示的信息），使您可以便捷地实现第三方账户的注册或第三方处直接登录。我们将在您授权同意的范围内使用您的相关信息。
</div>
<div style="font-size:14px;">
	我们基于始智AI/wisemodel平台账户为您提供服务。在您创建平台账户、签署我们的用户协议（CLA）、订阅邮件列表、使用模型库和数据集相关功能时，您需要至少向我们提供您的<span style="font-weight:bold;">手机号码、电子邮件地址、拟使用的账户名和密码、头像、昵称、性别、浏览器设置信息</span>。如果您拒绝提供上述信息，我们可能将无法为您提供账户注册、模型上传下载、在线使用工具、评论等服务，但不影响您进行浏览。
</div>
<div style="font-size:14px;">
	（二）为您提供收藏与关注功能
</div>
<div style="font-size:14px;">
	在您浏览我们网站或客户端的过程中，您可以选择对感兴趣的模型和数据集进行收藏、与您感兴趣的组织和研究机构或其他用户建立关注关系。在您使用上述功能的过程中，我们会收集包括您的收藏记录、关注关系在内的服务日志信息用于实现上述功能及其他我们明确告知的目的。您可以在收藏夹、关注列表或删除您的收藏和关注信息。
</div>
<div style="font-size:14px;">
	（三）&nbsp;客服及争议处理
</div>
<div style="font-size:14px;">
	当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，<span style="font-weight:bold;">我们需要您提供必要的个人信息以核验您的用户身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括账户信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体问题进行咨询、投诉或提供建议的，我们会使用您的账户信息。</span>
</div>
<div style="font-size:14px;">
	为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
</div>
<div style="font-size:14px;">
	&nbsp;<span style="font-weight:bold;">（四）&nbsp;为您提供安全保障</span>
</div>
<div style="font-size:14px;">
	为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或魔搭平台相关协议规则的情况，我们在平台网站中嵌入我们关联公司开发的应用安全SDK收集您的设备信息、服务日志信息，并可能使用或整合您的用户信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。
</div>
<div style="font-size:14px;">
	（五）收集和使用个人信息的其他规则
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			若你提供的信息中含有其他用户的个人信息，在向本平台提供这些个人信息之前，您需确保您已经取得合法的授权。若我们将信息用于本隐私权政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方说明其个人信息来源，以及是否已经就其收集、处理以及向我们提供您的个人信息取得了您的合法授权。除法律明确允许的情形外，我们只会在确认第三方已经取得您的授权同意后再从第三方收集您的个人信息，如果第三方的授权范围无法涵盖我们的处理和使用目的时，我们会自行或者要求该第三方征得您的同意后再行处理您的个人信息。同时，我们的专业安全团队对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			征得授权同意的例外。根据相关法律法规规定，我们在以下情况下收集、使用您的个人信息无需您的授权同意：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）与我们履行法律法规规定的义务相关的；
</div>
<div style="font-size:14px;">
	（2）与国家安全、国防安全直接相关的；
</div>
<div style="font-size:14px;">
	（3）与公共安全、公共卫生、重大公共利益直接相关的；
</div>
<div style="font-size:14px;">
	（4）与犯罪侦查、起诉、审判和判决执行等司法或行政执法直接相关的；
</div>
<div style="font-size:14px;">
	（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
</div>
<div style="font-size:14px;">
	（6）您自行向社会公众公开的个人信息；
</div>
<div style="font-size:14px;">
	（7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
</div>
<div style="font-size:14px;">
	（8）根据与您签订和履行相关协议或其他书面文件所必需的；
</div>
<div style="font-size:14px;">
	（9）用于维护所提供的服务的安全稳定运行所必需的，例如发现、处置服务的故障；
</div>
<div style="font-size:14px;">
	（10）法律法规规定的其他情形。
</div>
<div style="font-size:14px;">
	请知悉，根据适用的法律，若我们<span style="font-weight:bold;">对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原</span>，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善本平台的内容和布局，改进我们的服务（包括使用匿名数据进行机器学习或模型算法训练），<span style="font-weight:bold;">则此类处理后数据的使用无需另行向您通知并征得您的同意</span>。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			如出现本平台停止运营的情形，我们会采取合理措施保护您用户信息安全，包括但不限于及时停止继续收集用户信息的活动；停止运营的通知将以逐一送达或公告的形式通知用户；对所持有的个人信息进行删除或匿名化处理。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">二、我们如何共享、转让、公开披露您的个人信息</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（一）共享</span>
</div>
<div style="font-size:14px;">
	除了以上约定的使用情况外，&nbsp;我们不会与本平台以外的公司、组织和个人共享您的个人信息，但以下情况除外：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			与授权合作伙伴共享：我们可能委托受信赖的合作伙伴来提供服务，因此我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于任何其他用途。对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（二）转让</span>
</div>
<div style="font-size:14px;">
	我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			在我们与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您用户信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（三）公开披露</span>
</div>
<div style="font-size:14px;">
	我们仅会在以下情况下，公开披露您的用户信息：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			获得您明确同意或基于您的主动选择，我们可能会公开披露您的用户信息；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			如果我们确定您出现违反法律法规或严重违反平台相关协议及规则的情况，为保护我们及平台用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或本平台相关协议、规则披露关于您的用户信息。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（四）共享、转让、公开披露用户信息时事先征得授权同意的例外</span>
</div>
<div style="font-size:14px;">
	根据相关法律法规规定，以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			与国家安全、国防安全有关的；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			与公共安全、公共卫生、重大公共利益有关的；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			与犯罪侦查、起诉、审判和判决执行等有关的；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您自行向社会公众公开的个人信息；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道;
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			与我们履行法律法规规定的义务相关的，或法律法规规定的其他情形。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	请知悉，根据适用的法律，<span style="font-weight:bold;">若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;"></span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">三、我们如何存储和保护您的个人信息安全</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（一）数据存储的基本规则</span>
</div>
<div style="font-size:14px;">
	我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非受到法律的允许。超出上述用户信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。
</div>
<div style="font-size:14px;">
	我们将收集到的您的用户信息存放在中华人民共和国境内，目前我们不会跨境传输或存储您的个人信息，将来如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的同意，并向您告知用户信息出境的目的、接收方、安全保障措施、安全风险等情况。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（二）数据保护技术措施</span>
</div>
<div style="font-size:14px;">
	我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的用户信息，防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们对本平台网站提供&nbsp;HTTPS&nbsp;协议安全浏览方式；我们会使用加密技术提高用户信息的安全性；采取身份鉴别技术进行用户身份验证；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（三）数据保护管理组织措施</span>
</div>
<div style="font-size:14px;">
	我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升个人信息的安全性。我们会设置个人信息保护负责人，并举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（四）个人信息安全事件的响应</span>
</div>
<div style="font-size:14px;">
	在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（五）您也应保护您的账户安全</span>&nbsp;
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">我们强烈建议您在使用本平台时不要使用非平台推荐的通信方式发送您的信息，也请您使用复杂密码，协助我们保护您的账户安全。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">请注意，您在使用本平台时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用本平台时共享甚至公开分享相关信息。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;"></span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">四、您如何管理您的个人信息</span>
</div>
<div style="font-size:14px;">
	您可以通过以下方式访问及管理您的个人信息：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您可以登录本平台网站查询、管理（变更、删除）使用本平台服务时而提交的基本信息。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			在以下情形中，您可以向我们提出删除用户信息的请求：
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	（1）如果我们处理用户信息的行为违反法律法规；
</div>
<div style="font-size:14px;">
	（2）如果我们收集、使用您的用户信息，却未征得您的明确同意；
</div>
<div style="font-size:14px;">
	（3）如果我们处理个人信息的行为严重违反了与您的约定。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</span>
</div>
<div style="font-size:14px;">
	在以下情形中，按照法律法规要求，我们将无法响应您的请求：
</div>
<div style="font-size:14px;">
	（1）与我们履行法律法规规定的义务相关的；
</div>
<div style="font-size:14px;">
	（2）与国家安全、国防安全直接相关的；
</div>
<div style="font-size:14px;">
	（3）与公共安全、公共卫生、重大公共利益直接相关的；
</div>
<div style="font-size:14px;">
	（4）与刑事犯罪侦查、起诉、审判和执行判决等直接相关的；
</div>
<div style="font-size:14px;">
	（5）有充分证据表明个人信息主体存在主观恶意或滥用权利的；
</div>
<div style="font-size:14px;">
	（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
</div>
<div style="font-size:14px;">
	（7）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
</div>
<div style="font-size:14px;">
	（8）涉及商业秘密的。
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			每个功能需要一些基本的用户信息才能得以完成（见本政策第一条）。除此之外，您可以通过本政策第八条释明路径联系我们给予或收回您的授权同意。当您收回同意后，我们将不再处理相应的用户信息。但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您可以通过本政策第八条释明路径联系我们提交账户注销申请（请注明“始智AI/wisemodel账户注销”）。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	&nbsp;在您主动删除用户信息之后，我们将停止为您提供服务，但您在使用服务期间提供或产生的信息我们仍需按照监管要求的时间进行保存。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">五、我们如何使用&nbsp;Cookie&nbsp;和同类技术</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（一）Cookie的使用</span>
</div>
<div style="font-size:14px;">
	为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储Cookie、Flash&nbsp;Cookie，或浏览器提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助于&nbsp;Cookie，网站能够存储您收藏的数据集、模型等数据。
</div>
<div style="font-size:14px;">
	如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。但如果您这么做，在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">（二）Cookie同类技术的使用</span>
</div>
<div style="font-size:14px;">
	除&nbsp;Cookie&nbsp;外，我们还会在网站上使用网站信标、像素标签、ETag等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的服务偏好，以便于我们主动改善用户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
</div>
<div style="font-size:14px;">
	ETag（实体标签）是在互联网浏览器与互联网服务器之间背后传送的HTTP协议标头，可代替Cookie。ETag可以帮助我们避免不必要的服务器负载，提高服务效率，节省资源、能源，同时，我们可能通过ETag来记录您的身份，以便我们可以更深入地了解和改善我们的产品或服务。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。但请注意，如果停用ETag，您可能无法享受相对更佳的产品或服务体验。
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">六、未成年人用户信息的特别约定</span>
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			我们主要面向成人提供服务。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			对于经父母或监护人同意使用网站而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			特别提醒，如果您是14周岁以下儿童的，在您使用我们的服务前，您和您的监护人应仔细阅读本政策，确保在取得监护人同意后，您才可在监护人的指导下使用我们的服务，确保您在使用我们的服务和进行交易时的安全。
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			若您是未成年人的父母或其他监护人，请您关注您监护的未成年人是否是在取得您的授权同意之后使用我们的服务的。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	&nbsp;如您对您所监护的未成年人的个人信息有疑问，请通过本政策第八条的联系方式与我们联系。
</div>
<div style="font-size:14px;">
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">七、本隐私政策如何更新</span>
</div>
<div style="font-size:14px;">
	因为我们的服务以及技术是一直跟着您以及其他的用户的需求在更新和优化的，因此在新的服务及业务流程变更时，我们可能会更新我们的隐私政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所应享有的权利。我们会在网站上发布对本政策所做的任何变更。
</div>
<div style="font-size:14px;">
	对于重大变更，我们还会提供更为显著的通知（包括我们会通过本平台网站公示的方式进行通知）。本政策所指的重大变更包括但不限于：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			我们的服务模式发生重大变化，如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			我们在控制权、组织架构等方面发生重大变化，如业务调整、破产并购等引起的发布者变更等；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			用户信息共享、转让或公开披露的主要对象发生变化；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			您参与用户信息处理方面的权利及其行使方式发生重大变化；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
		</li>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			用户信息安全影响评估报告表明存在高风险时。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	我们可能会暂停或终止本平台网站的任何功能，或者我们可能引入新功能或对某些功能施加限制，或限制部分或全部服务的访问。当我们对本平台进行可能对您造成不利影响的重大更改时，我们会尽合理努力通知您，<span style="font-weight:bold;">您继续使用本平台表示您同意更新后的隐私政策。如您不同意更新后的隐私政策，请立即停止使用本平台。</span>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">八、如何联系我们</span>
</div>
<div style="font-size:14px;">
	如您对本政策内容有任何疑问、意见或建议，您可以通过以下方式与我们联系，我们将在&nbsp;15&nbsp;天内回复您的请求：
</div>
<div>
	<ol>
		<li style="font-size:14px;text-align:left;font-family:&quot;">
			向我们的个人信息保护负责人发送邮件，电子邮箱：contact@wisemodel.cn；需注意的是，我们可能不会回复与本政策或您的个人信息权利无关的问题。
		</li>
	</ol>
</div>
<div style="font-size:14px;">
	<span style="font-weight:bold;">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向北京市海淀区的法院提起诉讼来寻求解决方案。</span>
</div>`
    }
  }
}
</script>
<style lang="scss" scoped>

.main {
  margin: 21px auto;
  height: calc(100% - 42px);
}

</style>
