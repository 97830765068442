import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import store from '@/store'
import { valiUsers } from '@/utils/validate'
import navbars from '@/config/narbars'
import api from '@/api'
import cookie from '@/utils/cookie'
import { log } from 'mathjs'
const CryptoJS = require("crypto-js");
const modulePaths = navbars.map((item) => item.path)

// 解决跳转当前路由path时console报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})
router.beforeEach((to, from, next) => {
  // 检查即将进入的路由是否有权限
  if (to.matched.some(record => record.name === 'model-dev'|| record.parent && record.parent.name === 'model-dev')) {
    // 获取动态路由参数  
    const routerName = to.params.routerName;
    const modelTag = to.params.modelTag;
    const pass = valiUsers()
    if (pass) {
      let data ={
        publisher_name:routerName,
        project_name_en:modelTag
      }
      api.project.verifyMSAccess(data).then((res) => {
            if(res.data<0){
              next({ name: 'NoAccessPage' });
            }else{
              next()
            }
      })
    }else{
      next({ name: 'NoAccessPage' });
    }
  }else{
    next()
  }
});
//检查权限
router.beforeEach((to, from, next) => {
  // 检查即将进入的路由是否有权限
  if (to.matched.some(record => record.name === 'model-detail' || record.name === 'dataset-detail' || record.name === 'code-detail')) {
    // 获取动态路由参数  
    const username_en = to.params.username_en;
    const project_name_en = to.params.project_name_en;
    const project_type = to.name;
    const pass = valiUsers()
    if (pass) {
      let data ={
        publisher_name:username_en,
        project_name_en:project_name_en,
        project_type:project_type
      }
      api.project.verifyProjAccess(data).then((res) => {
            if(res.data<0){
              next({ name: 'NoAccessPage' });
            }else{
              next()
            }
      })
    }else{
      next();
    }
  }else{
    next()
  }
});
router.beforeEach((to, from, next) => {
  // 检查即将进入的路由是否有权限
  if (to.matched.some(record => record.name === 'modifyinfos')) {
  
    // 获取动态路由参数  
    const biz_id = to.params.codeid;
    const pass = valiUsers()
    if (pass) {
    
      
      let data ={
        biz_id:biz_id,
      }
      api.project.getInfosDetail(data).then((res) => {
          console.log(res);
          
            if(res.data.is_author===0){
              next({ name: 'NoAccessPage' });
            }else{
              next()
            }
      })
    }else{
      next({ name: 'NoAccessPage' });
    }
  }else{
    next()
  }
});
//检查各种模型详情数据集详情等是否有权限访问,
// router.beforeEach((to, from, next) => {
//   // 检查即将进入的路由是否有权限
//   if (to.matched.some(record => record.name === 'model-detail' || record.name === 'dataset-detail' || record.name === 'modelspacesdetail' || record.name === 'apispacesdetail' || record.name === 'code-detail')) {
//     // 获取动态路由参数  
//     const pass = valiUsers()
//     if (pass) {
//       const token = localStorage.getItem('token')
//       if (token !== null) {
//         let key1 = "wisemodelapitoken9621234";
//         var key = CryptoJS.enc.Utf8.parse(key1);
//         let encryptedToken = CryptoJS.AES.encrypt(to.path, key,{ iv: key, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).ciphertext.toString();
//         cookie.setCookie('visittoken', token, 3)
//         cookie.setCookie('visiinfo',encryptedToken, 3)
//         api.project.getuserjurisdiction().then((res)=>{
//           console.log(res);
//         })
//       }
//     }else{
//       next({ name: 'NoAccessPage' });
//     }
//   }else{
//     next()
//   }
// });
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.name === 'Edgeais')) {
    let datainfo = {
      click_event:'edgeailistevent',
      click_event_data:''
    }
   api.project.reportUserClickEvent(datainfo).then(res => {  }) 
    next()
  }else{
    next()
  }
});
router.beforeEach((to, from, next) => {
  let matchPaths = to.matched?.map((i) => i.name)
  matchPaths.forEach((i) => {
    if (modulePaths.includes('/' + i)) {
      store.commit('system/setPresentModule', '/' + i)
    }
  })
  if (to.path.includes('/model')) {
    store.dispatch('project/setType', 'M')
  } else if (to.path.includes('/dataset')) {
    store.dispatch('project/setType', 'D')
  } else if (to.path.includes('/codes')) {
    store.dispatch('project/setType', 'C')
  }
  next()
})

export default router
