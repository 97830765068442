// export default function throttle(func, delay) {
//   let timerId = null
//   return function(...args){
//     if(!timerId){
//       timerId = setTimeout(() => {
//         func.apply(this,args)
//         timerId=null
//       }, delay);
//     }
//   }
// }
export default function debounce(func, delay) {
  let timerId;
  
  return function(...args) {
    clearTimeout(timerId);
    
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
