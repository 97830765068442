import path from '@/api/path'
import service from '@/api/service'

const { collect } = path

export default {
  // 我的收藏
  list: (data) => {
    return service.post(collect.list, data)
  },
  // 添加收藏
  add: (data) => {
    return service.post(collect.add, data)
  },
  // 取消收藏
  del: (data) => {
    return service.delete(collect.del, data)
  }
}
