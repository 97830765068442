export const header = {
  home: '首页',
  model: '模型',
  datasets: '数据集',
  codes: '代码',
  spaces: '应用',
  cp: '算力',
  infos:'资讯',
  docs:'文档',
  donation:'捐赠'
}

export const home ={
  
}