import path from '@/api/path'
import service from '@/api/service'

export default {
  // 下拉选项options
  getOptions: (data) => {
    return service.post(path.project.getOptions, data)
  },
  //镜像列表options
  getModelImages: (data) => {
    return service.post(path.project.getModelImages, data)
  },
  getModelImagesapi: (data) => {
    return service.post(path.project.getModelImagesapi, data)
  },
  //获取训练用镜像列表options
  getModelImagesForTrains: (data) => {
    let framework={
      framework_code:'324werffsfd3'
    }
    return service.post(path.project.getModelImagesForTrain, framework)
  },
  //休眠时间
  getTimeRange: (data) => {
    return service.post(path.project.getTimeRange, data)
  },
  getXPUInfo: (data) => {
    return service.post(path.project.getXPUInfoList, data)
  },
  // 账户信息get
  getAccountinfo: () => {
    return service.get(path.accountinfo)
  },
  // 账户信息post
  postAccountinfo: (data) => {
    return service.post(path.accountinfo, data)
  },
  // 基本信息get
  getBaseinfo: () => {
    return service.get(path.baseinfo)
  },
  // 基本信息post
  postBaseinfo: (data) => {
    return service.post(path.baseinfo, data)
  },
  // 新建组织
  organize: (data) => {
    return service.post(path.organize, data)
  },
  // 邀请成员加入
  member: (data) => {
    return service.post(path.member, data)
  }
}
