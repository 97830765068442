import api from '@/api'
import { message } from '@/utils/resetMessage'
import { Message } from 'element-ui'

let state = {
  presentModule: '/home',
  userInfo: null,
  userBaseInfo: null, // 用户基本信息
  showLoginModel: false, // 显示登录model
  perfectInfoModel: false, // 完善信息model
  unReadMessageNum: 0 // 未读消息
}

const mutations = {
  setPresentModule(state, n) {
    state.presentModule = n
  },
  setCancelAxios(state, n) {
    state.cancelAxios = n
  },
  setUserInfo(state, n) {
    state.userInfo = n
  },
  setUserBaseInfo(state, n) {
    state.userBaseInfo = n
  },
  toLogin(state, n) {
    state.showLoginModel = false
    state.showLoginModel = n
  },
  perfectInfo(state, n) {
    state.perfectInfoModel = false
    state.perfectInfoModel = n
  },
  setUnReadMessageNum(state, n) {
    state.unReadMessageNum = n
  }
}

const actions = {
  setPresentModule({ commit }, n) {
    commit('setPresentModule', n)
  },
  setCancelAxios({ commit }, n) {
    commit('setCancelAxios', n)
  },
  setUserInfo({ commit }, n) {
    commit('setUserInfo', n)
  },
  setUserBaseInfo({ commit }, n) {
    commit('setUserBaseInfo', n)
  },
  toLogin({ commit }, n) {
    commit('toLogin', n)
  },
  perfectInfo({ commit }, n) {
    commit('perfectInfo', n)
  },
  setUnReadMessageNum({ state, commit }) {
    if (state.userInfo) {
      api.notice.unreadCount().then((res) => {
        const { message } = res
        if (message === 'Success') {
          const { dis_total, org_total, sys_total } = res.data
          commit('setUnReadMessageNum', {
            all: dis_total + org_total + sys_total,
            sys: sys_total,
            org: org_total,
            dis: dis_total
          })
        } else {
          //Message.error(message)
        }
      }).catch(error => {
        Message.error('网络连接异常')
        // state.userInfo = null
        // state.userBaseInfo = null
        // localStorage.clear()
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
