export default [
  // { id: 1, path: '/home', name: "header.home" },
  {
    id: 2,
    path: '/model',
    name: 'header.model'
  },
  {
    id: 3,
    path: '/dataset',
    name: 'header.datasets'
  },
  {
    id: 7,
    path: '/code',
    name: 'header.codes'
  },
  {
    id: 6,
    path: '/modelspaces',
    name: 'header.spaces'
  },
  {
    id: 5,
    
    path: '/training',
    name: 'header.cp'
  },
  {
    id: 8,
    path: '/info',
    name: 'header.infos'
  },
  { id: 4, path: '/docs', name: 'header.docs' }
]
