<template>
  <div class="page page-home">
    <div class="btn-group">

      <el-button :type="typeValue" ref="uploadFile" size="medium" @click="upload"
        :disabled="FileisDisabled">上传文件</el-button>
 
      <div class="mt-10px text-grey" v-if="!showNetDiskLink && !currShowAllContent">
        上传文件：网页端支持大文件上传，超过100M的文件完成上传后，需处理5-10分钟才能在页面显示，请知悉！完成处理之后会收到系统消息。
      </div>
    
    
    </div>
  </div>
</template>

<script>

import debounce from '@/utils/finishAdd.js'
import Bus from '/vue-simple-uploader/js/bus'
import {eventBus} from '@/utils/eventBus.js'

export default {
 
  inject:{
    getList:{value:'getList',default:null}
  },
  props: {
    ExtraParams: {},
    FileisDisabled: false,
    NetdiskisDisabled: false,
    ShowAllContent: true,
    clustercodes:'',
  },

  data() {
    return {

      addNetDiskLink: '',
      showNetDiskLink: false,
      isDisabled: true

    }
  },
  mounted() {    
    // 文件选择后的回调
    Bus.$on('fileAdded', () => {
      console.log('文件已选择')
    })

    // 文件上传成功的回调
    Bus.$on('fileSuccess', (data) => {
      this.$emit('success', data)
    })
    //自动执行提交功能，防抖防止多个小文件同时上传成功多次提交出现bug
    Bus.$on('fileSuccess', debounce((data) => {
      console.log('文件上传成功')
      this.$message.success('上传成功')
     this.finishAddFile()
    },3000))

    // 文件已存在的回调
    Bus.$on('fileHasExist', (data) => {
      console.log('文件已存在')
      this.$message.success('文件已存在，秒传成功')
      this.$emit('success', data)
      this.finishAddFile()//完成按钮的功能
    })
    // 文件已存在的回调
    Bus.$on('fileError', (data) => {
      console.log('文件已存在')
      this.finishAddFile()
    })
  },

  beforeDestroy() {
    Bus.$off('fileAdded')
    Bus.$off('fileSuccess')
    Bus.$off('fileHasExist')
  },
  computed: {
    typeValue() {
      if (this.currShowAllContent) {
        return 'primary';
      }
      if (this.showNetDiskLink) {
        return 'info';

      } else {
        return 'primary';
      }

    },
    netdiskTypeValue() {
      if (this.currShowAllContent) {
        return 'primary';
      }
      if (this.showNetDiskLink) {
        return 'primary';

      } else {
        return 'info';
      }

    },
    currShowAllContent: {
      get() {

        return this.ShowAllContent
      },
      set(newvalue) {
        return newvalue

      }

    }
  },
  created() {
    eventBus.$emit('getcode',this.clustercodes)
  },
  methods: {
    //上传成功
     //文件上传成功完成按钮
     finishAddFile(){
      eventBus.$emit('finishAddFile')
     },
    upload() {
      if (!this.$store.state.system.userInfo) {
        this.$store.dispatch('system/toLogin', true)
        return
      }
      if (this.showNetDiskLink || this.currShowAllContent) {
        this.showNetDiskLink = false


      }
      this.$emit('saveNetDiskLink', 'cancleNetDisk')
      // 打开文件选择框
      Bus.$emit('storageopenUploader', {
        // 给服务端的额外参数
        params: this.ExtraParams,
        clustercodes:this.clustercodes,//选择的集群代码
        fileVerifyFn: this.fileVerify
      })

    },
    closeUploader() {

      Bus.$emit('storagecloseUploader')
    },
    // 文件校验
    fileVerify(file) {
      let res = null
      if (!file) {
        res = false
      } else {
        /* if (file.size > 50 * 1024 * 1024) {
           this.$message.error('文件超出50M，请通过git方式上传')
           res = false
         } else {
           res = true
         }*/
        res = true
      }
      return res
    },
    setNetDiskLink() {
      if (!this.$store.state.system.userInfo) {
        this.$store.dispatch('system/toLogin', true)
        return
      }
      if (!this.showNetDiskLink) {
        this.showNetDiskLink = true

      }
      this.$emit('saveNetDiskLink', 'hideAllContent')

    },
    saveNetDiskLink() {


      this.$emit('saveNetDiskLink', this.addNetDiskLink)
    }

  }
}
</script>

<style scoped lang="scss">

.page-home {
  height: 100%;
}

.file-box {
  position: relative;
  margin-top: 20px;
  padding: 30px;
  flex: 1;
  background-color: #fff;

  .title {
    padding-left: 10px;
    font-size: 15px;
    border-left: 4px solid #1989fa;
  }

  .empty {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    .upload {
      color: #1989fa;
      cursor: pointer;
    }
  }
}

</style>
