import path from '@/api/path'
import service from '@/api/service'

const { comment } = path

export default {
  // 创建建议
  add: (data) => {
    return service.post(comment.add, data)
  }
}
