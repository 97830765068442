import Vue from 'vue'
import Vuex from 'vuex'

import system from './modules/system'
import project from './modules/project'
import selectOptions from './modules/selectOptions'

import createPersistedstate from 'vuex-persistedstate' // 持久化

Vue.use(Vuex)

export default new Vuex.Store({
  state: { 
    metaInfo: { }
},
mutations: {
    CAHNGE_META_INFO(state, metaInfo) { 
        state.metaInfo = metaInfo;
    }
},    
  modules: {
    system,
    project,
    selectOptions
  },
  plugins: [
    createPersistedstate({
      key: 'persistedstate' // 存数据的key名   自定义的  要有语义化
    })
  ]
})
