<template>
  <div class="w-full flex flex-col" style="margin-top: 35px;">
    <Header></Header>
    <div style="background-color: #f7f3fc">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from './header'
export default {
  components: { Header },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>

</style>
