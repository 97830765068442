<template>
  <div>
    <el-tabs v-model="activeName"  @tab-click="handleClick">
      <el-tab-pane label="线上支付" name="first">
        <el-form ref="form" :model="formData" label-width="80px" size="mini">
          <el-form-item label="充值金额:" :rules="amountRules" required>
            <el-radio-group
              v-model="formData.resource"
              size="medium"
              @input="inputt"
            >
              <el-radio border label="10元"></el-radio>
              <el-radio border label="30元"></el-radio>
              <el-radio border label="50元"></el-radio>
              <el-radio border label="100元"></el-radio>

              <el-input
                class="w-90px h-36px"
                placeholder="自定义"
                @focus="payFocus"
                v-model="formData.amount"
                @input="handleInput"
              ></el-input>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="支付方式:">
            <el-radio-group v-model="formData.payMethods" size="medium">
              <el-radio border label="微信支付"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="确认支付:">
              <el-button type="primary" @click="handlePay">确认支付</el-button>
          </el-form-item>
          <el-form-item label="扫码支付">
            <div class="w-200px h-200px" style="" v-loading="loading" v-show="is_show">
              <img :src="out_time?code_fail:wx_url" alt="" class="w-200px h-200px" style="border: 1px solid black; border-radius: 5px;" @click="resturl">
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="对公转账" name="second">
        <el-table
    :data="bankData"
    border
    style="width: 100%">
    <el-table-column
      prop="name"
      label="账户名称"
      width="180">
    </el-table-column>
    <el-table-column
      prop="bank"
      label="开户银行"
      width="300">
    </el-table-column>
    <el-table-column
      prop="account"
      label="账号">
    </el-table-column>
  </el-table>
  <el-alert
    title="转账时备注信息请注明“用户名”，否则可能无法确认具体充值用户"
    type="error">
  </el-alert>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
  data() {
    return {
      code_fail: require('@/assets/images/payment/code_fail .png'),
      bankData: [{
          name: '北京始智科技有限公司 ',
          bank: '招商银行股份有限公司北京清华园科技金融支行',
          account: '1109 4439 0610 902',
        }],
      loading:false,
      is_show:false,
      activeName: 'first',
      out_time:true,
      is_public: 0,
      formData: {
        amount: null,
        resource: null,
        payMethods: '微信支付'
      },
      wx_url:'',
      paydata: {
        description: '始智AI wisemodel平台充值',
        amount: {
          total2: 0
        },
       
      },
      payCode:'',
      timer:null,
      payTimeoutId:null,
      amountRules: [
        { required: true, message: '请输入支付金额', trigger: 'blur' },
        { validator: this.validateAmount, trigger: 'blur' }
      ]
    }
  },
  
  methods: {
    //关闭弹窗重置表单
    resetForm() {
      this.formData.amount=null
      this.formData.resource=null
  },

    handleClick(tab, event) {
      console.log(tab, event)
    },
    //自定义输入框获取焦点
    payFocus() {
      this.paydata.amount.total2=0
      this.is_show=false
      this.formData.resource = ''
      this.stopPaymentPolling()
    },
    //确认支付
    handlePay(){
      if(this.paydata.amount.total2!=0){
        this.loading=true
      this.is_show=true
      this.createPayment()
      }else{
        this.$message.warning('请选择支付金额,或填写有效数字')
      }
    
    },
    //请求wx协议转码
    createPayment() {
      this.$api.project.wxpayMent(this.paydata).then((res) => {
        console.log(res);
        this.payCode=res.data.out_trade_no
        let code_url = res.data.code_url
        // let code_url = 'weixin://wxpay/bizpayurl?pr=5CGgREgz3'
        QRCode.toDataURL(code_url)
          .then((url) => {
              this.wx_url=url
              this.loading=false
              this.out_time=false
              this.checkPay()
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    //支付结果查询
    checkPay(){
      if(this.payCode!=''){
          this.timer=setInterval(() => {
            this.$api.project.checkPay(this.payCode).then((res)=>{
                console.log(res);
                let out_trade_state= res.data.out_trade_state
                if(out_trade_state==='SUCCESS'){
                  this.stopPaymentPolling();
                  this.$message.success('充值成功')
                  this.close()
                }
                if(out_trade_state!='SUCCESS'&& out_trade_state!='USERPAYING' ){
                  this.stopPaymentPolling();
                  this.$message.error('支付失败')
                  this.out_time=true
                }
              })
          }, 2000);
      }
      const that = this
      this.payTimeoutId = setTimeout(function() {
        that.stopPaymentPolling();
        that.out_time=true
      }, 1000 * 60 * 5);
    },
    //轮询五分钟停止结束轮询
    stopPaymentPolling(){
      clearInterval(this.timer)
      clearTimeout(this.payTimeoutId)
    },
    //父组件事件调用
    close(){
      this.$emit('close_pay')
    },
    //支付失败重置二维码
    resturl(){
      if(this.out_time){
        this.out_time=false
        this.createPayment()
      }
    },
    //金额选项改变
    inputt() {
      this.loading=false
      this.formData.amount = ''
      this.paydata.amount.total2 = parseFloat(this.formData.resource)
      this.is_show=false
      this.stopPaymentPolling()
    },
    handleInput() {
      this.loading=false
      this.paydata.amount.total2 = 0
      this.is_show=false
      this.stopPaymentPolling()
      // 移除非数字和多余的小数点
      this.formData.amount = this.formData.amount
        .toString()
        .replace(/[^\d.]/g, '')
        .replace(/\.{2,}/g, '.')

      // 限制小数位数
      const amountParts = this.formData.amount.toString().split('.')
      if (
        amountParts.length > 1 &&
        amountParts[1] &&
        amountParts[1].length > 2
      ) {
        amountParts[1] = amountParts[1].slice(0, 2)
        this.formData.amount = parseFloat(amountParts.join('.'))
      }
      this.paydata.amount.total2 = parseFloat(this.formData.amount)
      clearInterval(this.timer)
      if(this.formData.amount==''){
        this.paydata.amount.total2=0
      }
      // if(this.formData.amount!=''&&this.formData.amount!=0 && this.paydata.amount.total2!=0){
      //   this.createPayment()
      // }
      
    }
  },
 
}
</script>

<style lang="scss" scoped>

.el-form .el-form-item__content .el-radio {
  line-height: 0;
}
.el-form .el-form-item__content {
  margin-left: 0 !important;
}
::v-deep{
  .el-tabs__item{
  font-size: 0.13rem;
}
}


</style>
