<template>
  <div class="w-full h-full">
    <img v-if="src" :src="url" class="w-full h-full" />
    <img v-else src="@/assets/images/user_avatar.png" class="w-full h-full" />
    <slot />
  </div>
</template>

<script>
import path from '@/api/path'

export default {
  props: {
    src: {},
    size: {
      default: 'small'
    }
  },
  components: {},
  data() {
    return {
      url: ''
    }
  },
  watch: {
    src: {
      handler(val) {
        if (val) {
          this.url = this.src
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>

img {
  border-radius: 50%;
}

</style>
