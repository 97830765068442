<template>
  <div class="search flex items-center h-full" @keydown.enter="keydownEnter">
    <img src="@/assets/images/magnifying_glass.png" class="searh-icon" />
    <el-input class="w-full h-full input flex items-center" :type="type" :placeholder="placeholder" v-bind="$attrs"
      v-on="$listeners" clearable ref="searchInput" />
    <div @click="search" class="search-btn flex justify-center items-center text-pri">
      <el-button type="text" :loading="loading"></el-button>
      搜索
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: 'text'
    },
    loading: {
      default: false
    },
    placeholder: {
      default: '请输入'
    }
  },
  data() {
    return {}
  },
  computed: {
    inputVal() {
      return this.$attrs.value
    }
  },
  watch: {
    inputVal(val) {
      if (val === '') {
        this.search()
      }
    }
  },
  created() { },
  mounted() { },
  methods: {
    search() {
      this.$emit('search')
      this.focusInput()
    },
    keydownEnter() {
      this.search()
    },
    focusInput() {
      this.$refs.searchInput.focus()
    }
  }
}
</script>

<style lang="scss" scoped>

.search {
  position: relative;
  background: #fff;
  font-size: 19px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;

  .searh-icon {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .bg {
    position: absolute;
    z-index: 0;
  }

  .input {
    ::v-deep .el-input__inner {
      border: none;
      padding: 0;
      font-size: 19px;
      width: calc(100% - 2px);
      height: calc(100% - 6px);
    }
  }

  .search-btn {
    height: 70%;
    width: 120px;
    border-left: 1px solid #f4e9ff;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
}

@media only screen and (max-width: 768px) {
  .search {
    .searh-icon {
      width: 32px;
      height: 32px;
    }
    .search-btn{
      width: 240px;
    }
  }
}

</style>
