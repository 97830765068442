import path from '@/api/path'
import service from '@/api/service'
// import renewModel from '@/views/model/deploy-model/renew-model.vue'

const { storage } = path


export default {
  //生产环境
  getShareFileList:data=>{
    let url =storage.getUserDir +'/'+data +'/storage/api/v1/getShareFileList'
    return service.post(url, )
  },
  //获取开发环境指定的目录的列表
  getShareModelDir:data=>{
    let url =storage.getUserDir +'/'+data.cluster_code +'/storage/api/v1/getShareModelDir'
    delete data.cluster_code
    return service.post(url, data)
  },
   //获取个人目录
   getUserDir:data=>{
    let url =storage.getUserDir +'/'+data.cluster_code +'/storage/api/v1/getUserDir'
    delete data.cluster_code
    return service.post(url, data)
  },
   //文件检查
   fileUploadCheck:data=>{
    let url =storage.fileUploadCheck +'/'+data.cluster_code +'/storage/api/v1/fileUploadCheck'
    delete data.cluster_code
    return service.post(url, data,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  //文件上传
  fileUpload:data=>{
    let url =storage.fileUpload +'/'+data.cluster_code +'/storage/api/v1/fileUpload'
    delete data.cluster_code
    return service.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  //文件分片合并
  fileMerge:data=>{
    let url =storage.fileMerge +'/'+data.cluster_code +'/storage/api/v1/fileMerge'
    delete data.cluster_code
    return service.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  //创建指定目录下的文件夹
  createFolder:data=>{
    let url =storage.createFolder +'/'+data.cluster_code +'/storage/api/v1/createFolder'
    delete data.cluster_code
    return service.post(url, data)
  },
  //删除指定目录下的文件夹或文件
  delFiles:data=>{
    let url =storage.delFiles +'/'+data.cluster_code +'/storage/api/v1/delFiles'
    delete data.cluster_code
    return service.post(url, data)
  },
  //文件重命名
  renameFile:data=>{
    let url =storage.renameFile +'/'+data.cluster_code +'/storage/api/v1/renameFile'
    delete data.cluster_code
    return service.post(url, data)
  },
}