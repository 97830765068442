import path from '@/api/path'
import service from '@/api/service'

const { notice } = path

export default {
  // 我的消息
  list: (data) => {
    return service.post(notice.list, data)
  },
  // 创建消息
  add: (data) => {
    return service.post(notice.add, data)
  },
  // 更新消息
  edit: (data) => {
    return service.put(notice.edit, data)
  },
  // 获取消息详情
  get: (id) => {
    return service.get(notice.get + '?notice_id=' + id)
  },
  // 获取未读消息
  unreadCount: (id) => {
    return service.get(notice.unreadCount)
  }
}
