<template>
  <div class="relative">
    <my-input class="flex-1 input-wrapper" placeholder="搜索模型和数据集" v-model="searchVal" @search="search"
      @blur="isSearch = false"></my-input>
    <!-- 搜索结果 -->
    <div class="search_result w-456px absolute left-48px p-20px" v-loading="loading" v-if="isSearch">
      <div class="w-full max-h-500px overflow-y-auto">
        <div class="text-24px font-bold">模型</div>
        <div v-if="searchModelLists.length > 0">
          <div class="list" v-for="(item, index) in searchModelLists" :key="index" v-on:mousedown="goModelDetail(item)">
            {{ item.project_name_zh }}
          </div>
        </div>
        <div v-else class="text-grey pl-20px pt-20px">
          暂未搜索到相关模型结果...
        </div>
        <div class="text-24px font-bold">数据集</div>
        <div v-if="searchDatasetLists.length > 0">
          <div class="list" v-for="(item, index) in searchDatasetLists" :key="index"
            v-on:mousedown="goDatasetDetail(item)">
            {{ item.project_name_zh }}
          </div>
        </div>
        <div v-else class="text-grey pl-20px pt-20px">
          暂未搜索到相关数据集结果...
        </div>
      </div>
      <div class="w-full text-right mt-10px">
        <el-button type="text" @click="isSearch = false">收起</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import myInput from '@/components/my-input.vue'
export default {
  components: { myInput },
  data() {
    return {
      searchVal: '',
      searchLists: [],
      isSearch: false,
      loading: false
    }
  },
  computed: {
    searchModelLists() {
      return this.searchLists.filter((i) => i.project_type == 'M')
    },
    searchDatasetLists() {
      return this.searchLists.filter((i) => i.project_type == 'D')
    }
  },
  created() { },
  mounted() { },
  methods: {
    search() {
      if (this.searchVal) {
        this.isSearch = true
        this.loading = true
        this.$api.project
          .queryprojectlist({
            project_name_zh: this.searchVal,
            baseReq: {
              page: 1,
              pageSize: 99999
            }
          })
          .then((res) => {
            const { message } = res
            if (message === 'Success') {
              this.searchLists = res.data.list
            } else {
              this.$message.error(message)
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    goModelDetail(data) {
      const { username_en, project_name_en } = data
      this.isSearch = false
      this.$router.push(`/models/${username_en}/${project_name_en}`)
    },
    goDatasetDetail(data) {
      const { username_en, project_name_en } = data
      this.isSearch = false
      this.$router.push(`/datasets/${username_en}/${project_name_en}`)
    }
  }
}
</script>

<style lang="scss" scoped>

.input-wrapper {
  height: 40px;
  margin-left: 48px;
}

.search {
  position: relative;

  .bg {
    position: absolute;
    z-index: 0;
  }

  .input {
    ::v-deep .el-input__inner {
      border: none;
      padding: 0;
      font-size: 19px;
      width: calc(100% - 2px);
      height: calc(100% - 6px);
    }
  }

  .search-btn {
    height: 70%;
    color: #e1dce9;
    border-left: 1px solid #f4e9ff;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
}

.search_result {
  background: #fff;
  z-index: 9;

  .list {
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .input-wrapper {
    height: 90px;
    margin-left: 48px;
  }
}

</style>
