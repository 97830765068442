<template>
  <div class="page page-home">
    <div class="btn-group">

      <el-button :type="typeValue" ref="uploadFile" size="medium" @click="upload"
        :disabled="FileisDisabled">上传文件</el-button>
      <!-- <el-button :type="netdiskTypeValue" @click="setNetDiskLink" size="medium"
        :disabled="NetdiskisDisabled">网盘上传</el-button> -->
      <div class="mt-10px text-grey" v-if="!showNetDiskLink && !currShowAllContent">
        上传文件：网页端支持大文件上传，超过100M的文件完成上传后，需处理5-10分钟才能在页面显示，请知悉！完成处理之后会收到系统消息。推荐通过git方式上传（详情参考

        <span class="text-pri cursor-pointer" @click="$router.push('/docs')">文档</span>）
      </div>
    
      <!-- <div class="mt-10px text-grey" v-if="showNetDiskLink && !currShowAllContent">

        网盘上传：提供完整的网盘链接（含提取码）后，后台顺序拉取文件，根据文件大小和数量，处理时间从几十分钟到几十小时不等，完成处理之后会收到系统消息。

      </div>
      <div class="mt-10px text-grey" v-if="currShowAllContent">
        <b>请选择文件上传方式</b> <br />
        上传文件：网页端支持大文件上传，超过100M的文件完成上传后，需处理5-10分钟才能在页面显示，请知悉！完成处理之后会收到系统消息。<br />
        网盘上传：提供完整的网盘链接（含提取码）后，后台顺序拉取文件，根据文件大小和数量，处理时间从几十分钟到几十小时不等，完成处理之后会收到系统消息。

      </div>
      <el-form-item label="" prop="netDiskLink">
        <el-input v-if="showNetDiskLink" v-model="addNetDiskLink" placeholder="添加网盘链接" show-word-limit
          @blur="saveNetDiskLink()"></el-input>
      </el-form-item> -->
    </div>
  </div>
</template>

<script>

import debounce from '@/utils/finishAdd.js'
import Bus from '/vue-simple-uploader/js/bus'
import {eventBus} from '@/utils/eventBus.js'

export default {
 
  inject:{
    getList:{value:'getList',default:null}
  },
  props: {
    ExtraParams: {},
    FileisDisabled: false,
    NetdiskisDisabled: false,
    ShowAllContent: true
  },

  data() {
    return {

      addNetDiskLink: '',
      showNetDiskLink: false,
      isDisabled: true

    }
  },

  mounted() {
  
   
    // 文件选择后的回调
    Bus.$on('fileAdded', () => {
      console.log('文件已选择')
    })

    // 文件上传成功的回调
    Bus.$on('fileSuccess', (data) => {
      this.$emit('success', data)
    })
    //自动执行提交功能，防抖防止多个小文件同时上传成功多次提交出现bug
    Bus.$on('fileSuccess', debounce((data) => {
      console.log('文件上传成功')
      this.$message.success('上传成功')
     this.finishAddFile()
    },3000))

    // 文件已存在的回调
    Bus.$on('fileHasExist', (data) => {
      console.log('文件已存在')
      this.$message.success('文件已存在，秒传成功')
      this.$emit('success', data)
      this.finishAddFile()//完成按钮的功能
    })
    // 文件已存在的回调
    Bus.$on('fileError', (data) => {
      console.log('文件已存在')
      this.finishAddFile()
    })
  },

  beforeDestroy() {
    Bus.$off('fileAdded')
    Bus.$off('fileSuccess')
    Bus.$off('fileHasExist')
  },
  computed: {
    typeValue() {
      if (this.currShowAllContent) {
        return 'primary';
      }
      if (this.showNetDiskLink) {
        return 'info';

      } else {
        return 'primary';
      }

    },
    netdiskTypeValue() {
      if (this.currShowAllContent) {
        return 'primary';
      }
      if (this.showNetDiskLink) {
        return 'primary';

      } else {
        return 'info';
      }

    },
    currShowAllContent: {
      get() {

        return this.ShowAllContent
      },
      set(newvalue) {
        return newvalue

      }

    }
  },
  methods: {
    //上传成功
     //文件上传成功完成按钮
     finishAddFile(){
      eventBus.$emit('finishAddFile')
     },
    upload() {
      if (!this.$store.state.system.userInfo) {
        this.$store.dispatch('system/toLogin', true)
        return
      }
      if (this.showNetDiskLink || this.currShowAllContent) {
        this.showNetDiskLink = false


      }
      this.$emit('saveNetDiskLink', 'cancleNetDisk')
      // 打开文件选择框
      Bus.$emit('openUploader', {
        // 给服务端的额外参数
        params: this.ExtraParams,
        fileVerifyFn: this.fileVerify
      })

    },
    closeUploader() {

      Bus.$emit('closeUploader')
    },
    // 文件校验
    fileVerify(file) {
      let res = null
      if (!file) {
        res = false
      } else {
        /* if (file.size > 50 * 1024 * 1024) {
           this.$message.error('文件超出50M，请通过git方式上传')
           res = false
         } else {
           res = true
         }*/
        res = true
      }
      return res
    },
    setNetDiskLink() {
      if (!this.$store.state.system.userInfo) {
        this.$store.dispatch('system/toLogin', true)
        return
      }
      if (!this.showNetDiskLink) {
        this.showNetDiskLink = true

      }
      this.$emit('saveNetDiskLink', 'hideAllContent')

    },
    saveNetDiskLink() {


      this.$emit('saveNetDiskLink', this.addNetDiskLink)
    }

  }
}
</script>

<style scoped lang="scss">

.page-home {
  height: 100%;
}

.file-box {
  position: relative;
  margin-top: 20px;
  padding: 30px;
  flex: 1;
  background-color: #fff;

  .title {
    padding-left: 10px;
    font-size: 15px;
    border-left: 4px solid #1989fa;
  }

  .empty {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    .upload {
      color: #1989fa;
      cursor: pointer;
    }
  }
}

</style>
