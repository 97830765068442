// 数组转树
export default function array2Tree(
  arr,
  // 字段名称配置
  nameOpt
) {
  if (!Array.isArray(arr) || !arr.length) return
  if (!nameOpt.id) {
    nameOpt.id = 'id' // 唯一标识字段
  }
  if (!nameOpt.id) {
    nameOpt.parentId = 'parentId' // 父级id
  }
  if (!nameOpt.id) {
    nameOpt.children = 'children' // 子树名称
  }
  let map = {}
  arr.forEach((item) => (map[item[nameOpt.id]] = item))

  let roots = []
  arr.forEach((item) => {
    const parent = map[item[nameOpt.parentId]]
    if (parent) {
      if (!parent[nameOpt.children]) {
        parent[nameOpt.children] = []
      }
      parent[nameOpt.children].push(item)
    } else {
      roots.push(item)
    }
  })

  return roots
}
