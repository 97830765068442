<template>
  <div class="h-full flex-1 ml-50px mr-64px navbar">
    <ul class="nav-menu">
      <li class="nav-item" v-for="item in navbarLists" :key="item.id" :class="{ active: isActive(item.path) }"
      
        @click="navbarClick(item)">
        <a :href="item.path==='/modelspaces'?'/apps':item.path" > {{ $t(item.name) }}</a>
      </li>
    </ul>
    <div class="hamburger">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>
  </div>
</template>

<script>
import navbars from '@/config/narbars'
import api from '@/api'
export default {
  components: {},
  data() {
    return {
      navbarLists: navbars
    }
  },
  created() { },
  mounted() {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector(".nav-menu");
    hamburger.addEventListener("click", mobileMenu);
    function mobileMenu() {
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
    }

    const navLink = document.querySelectorAll(".nav-item");
    navLink.forEach(n => n.addEventListener("click", closeMenu));
    function closeMenu() {
      hamburger.classList.remove("active");
      navMenu.classList.remove("active");
    }
  },
  methods: {
    isActive(path) {
      let activeModule = this.$store.state.system.presentModule
      return path === activeModule
    },
    navbarClick(barItem) {
      if (barItem.waitting) {
        this.$message.warning('敬请期待')
        return
      }
      //统计各个板块点击次数
      this.$router.push({
        path: barItem.path
      })
      if(barItem.path=='/docs')return
      let eventpath = ''
      if(barItem.path=='/modelspaces'){
        eventpath='/apps'
      }else{
        eventpath=barItem.path
      }
      let eventname = eventpath.substr(1)+'listevent'
      let data ={
        click_event:eventname,
        click_event_data:''
      }
      api.project.reportUserClickEvent(data).then(res => {  })
    }
  }
}
</script>

<style lang="scss" scoped>

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 100;

  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .active {
      color: var(--primary-color);
      border-bottom: 3px solid var(--primary-color);
      font-weight: bold;
    }
  }

  .nav-item {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .nav-item:hover {
    color: var(--primary-color);
  }
}

.hamburger {
  display: none;
}
a {
  color: inherit;        /* 去除链接颜色 */
  text-decoration: none; /* 去除下划线 */
  background-color: transparent; /* 去除背景色 */
  font-weight: normal;   /* 去除加粗样式 */
  font-style: normal;    /* 去除斜体样式 */
 
}
.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.hamburger.active {
  position: absolute;
    right: 30px;
    z-index: 110;
}


@media only screen and (max-width: 768px) {
  .bar {
    width: 60px;
    height: 8px;
    margin: 15px auto;
  }
}

@media only screen and (max-width: 1280px) {
  .navbar {
    .nav-menu {
      position: fixed;
      left: -100%;
      top: 0;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      height: auto;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-item {
      height: 110px;
      margin: 20px 0;
    }

    .nav-menu.active {
      left: 0;
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
  }
}

</style>
