/*
state 里面的 branchs 是某个模型/数据集里面的的分支列表
type 是区分 project 是模型还是数据集
*/

let state = {
  branchs: [],
  type: '',
  projectId: -1
}

const mutations = {
  setBranchs(state, n) {
    state.branchs = n
  },
  setType(state, n) {
    state.type = n
  },
  setProjectId(state, id) {
    state.projectId = id
  }
}

const actions = {
  setBranchs({ commit }, n) {
    commit('setBranchs', n)
  },
  setType({ commit }, n) {
    commit('setType', n)
  },
  setProjectId({ commit }, id) {
    commit('setProjectId', id)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
