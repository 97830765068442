// 重置message，防止重复点击重复弹出message弹框
import { Message } from 'element-ui'
let messageInstance = null
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
}
// info success warning error
let typeArr = ['info', 'success', 'warning', 'error']
typeArr.forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})
// 关闭当前弹窗
resetMessage.closeAll = () => {
  Message.closeAll()
}
export const message = resetMessage
