import path from '@/api/path'
import service from '@/api/service'

const { organize } = path

export default {
  // 我的组织
  list: (data) => {
    return service.post(organize.list, data)
  },
  // 获取组织详情
  get: (enName) => {
    return service.get(organize.get + '?orgEn=' + enName)
  },
  // 创建组织
  add: (data) => {
    return service.post(organize.add, data)
  },
  // 更新组织
  put: (data) => {
    return service.put(organize.put, data)
  },
  // 删除组织
  del: (data) => {
    return service.delete(organize.del, data)
  },
  // 邀请加入组织
  invite: (data) => {
    return service.post(organize.invite, data)
  },
  // 移除组织成员
  remove: (data) => {
    return service.delete(organize.remove, { data })
  },
  // 组织列表
  queryorglist: (data) => {
    return service.post(organize.queryorganizelist, data)
  }
}
