<template>
  <div id="global-uploader" :class="{ 'global-uploader-single': !global }">
    <!-- 上传 -->
    <uploader
      ref="uploader"
      :options="initOptions"
      :fileStatusText="fileStatusText"
      :autoStart="false"
      @file-added="onFileAdded"
      @file-success="onFileSuccess"
      @file-progress="onFileProgress"
      @file-error="onFileError"
      class="uploader-app"
    >
      <uploader-unsupport></uploader-unsupport>

      <uploader-btn id="global-uploader-btn" ref="uploadBtn"
        >选择文件</uploader-btn
      >
    
    <uploader-list v-show="panelShow">
        <div
          class="file-panel"
          slot-scope="props"
          :class="{ collapse: collapse }"
         
        >
          <div class="file-title">
            <div class="title">文件列表</div>
            <div class="operate">
              <el-button
                @click="collapse = !collapse"
                type="text"
                :title="collapse ? '展开' : '折叠'"
              >
                <i
                  class="iconfont"
                  :class="collapse ? 'el-icon-full-screen' : 'el-icon-minus'"
                ></i>
              </el-button>
              <el-button @click="close" type="text" title="关闭">
                <i class="el-icon-close"></i>
              </el-button>
            </div>
          </div>

          <ul class="file-list">
            <li class="file-item" v-for="file in props.fileList" :key="file.id">
              <uploader-file
                :class="['file_' + file.id, customStatus]"
                ref="files"
                :file="file"
                :list="true"
              ></uploader-file>
            </li>
            <div class="no-file" v-if="!props.fileList.length">
              <i class="iconfont icon-empty-file"></i> 暂无待上传文件
            </div>
          </ul>
        </div>
      </uploader-list>
      
      
    </uploader>
  </div>
</template>

<script>
/**
 *  全局上传插件，两种调用方式
 *   1. 作为全局页面的组件，使用event bus
 *   调用方法：Bus.$emit('openUploader', {params: {}, options: {}})
 *               params: 发送给服务器的额外参数；
 *               options：上传选项，目前支持 target、testChunks、mergeFn、accept
 *
 *   监听函数：Bus.$on('fileAdded', fn); 文件选择后的回调
 *           Bus.$on('fileSuccess', fn); 文件上传成功的回调，监听后记得释放
 *
 *   2. 作为普通组件在单个页面中调用，使用props
 */
// import { ACCEPT_CONFIG } from './js/config'
import Bus from './js/bus'
import SparkMD5 from 'spark-md5'
import path from '@/api/path'
import glob from '@/components/global-uploader.vue'
import {eventBus} from '@/utils/eventBus.js'
export default {
  components:{glob},
  props: {
    global: {
      type: Boolean,
      default: true
    },
    // 发送给服务器的额外参数
    params: {
      type: Object
    },
    options: {
      type: Object
    },
    fileVerifyFn: {
      type: Function
    }
  },

  data() {
    return {
      // 默认配置  具体参考 https://github.com/simple-uploader/Uploader/blob/develop/README_zh-CN.md
      initOptions: {
        target: path.file.upload,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        chunkSize: '2048000',
        fileParameterName: 'file',
        maxChunkRetries: 3,
        // simultaneousUploads: 8,
        // 是否开启服务器分片校验
        testChunks: false,
        // 服务器分片校验函数，秒传及断点续传基础
        checkChunkUploadedByResponse: (chunk, message) => {
          console.log('checkChunkUploadedByResponse')
          return false
        },
        query: (file, chunk) => {
          const { path: dir, project_id } = file.params
          return {
            md5: file.md5,
            dir,
            project_id,
            chunk: chunk.offset
          }
        },
        processParams: (params, file) => {
          const { chunkNumber: chunk, md5, dir, project_id } = params
          return {
            md5,
            dir,
            project_id,
            chunk
          }
        }
      },
      fileStatusText: {
        success: '上传成功',
        error: '上传失败',
        uploading: '上传中',
        paused: '已暂停',
        waiting: '等待上传'
      },
      panelShow: false, //选择文件后，展示上传panel
      collapse: false,
      customParams: {},
      customStatus: '',
      fileVerify: null
    }
  },

  watch: {
    params: {
      handler(data) {
        if (data) {
          this.customParams = data
        }
      },
      immediate: true
    },
    options: {
      handler(data) {
        if (data) {
          setTimeout(() => {
            this.customizeOptions(data)
          }, 0)
        }
      },
      immediate: true
    }
  },

  mounted() {
    Bus.$on(
      'openUploader',
      ({ params = {}, options = {}, fileVerifyFn = {} }) => {
        console.log('openUploader')
        this.customParams = params
        this.fileVerify = fileVerifyFn
        options.headers = {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
        this.customizeOptions(options)

        if (this.$refs.uploadBtn) {
          this.$refs.uploadBtn.$el.click()
        }
      }
    )

    Bus.$on('closeUploader', () => {
      console.log('closeUploader')
      this.close()
    })
  },

  computed: {
    // Uploader实例
    uploader() {
      return this.$refs.uploader.uploader
    }
  },

  methods: {
    finishAddFile(){
      eventBus.$emit('finishAddFile')
     },
    // 自定义options
    customizeOptions(opts) {
      // 自定义上传url
      if (opts.target) {
        this.uploader.opts.target = opts.target
      }

      // 是否可以秒传、断点续传
      if (opts.testChunks !== undefined) {
        this.uploader.opts.testChunks = opts.testChunks
      }
      // 更新token
      if (opts.headers !== undefined) {
        this.uploader.opts.headers = opts.headers
      }

      // 自定义文件上传类型（暂时不做限制）
      // let input = document.querySelector('#global-uploader-btn input')
      // let accept = opts.accept || ACCEPT_CONFIG.getAll()
      // input.setAttribute('accept', accept.join())
    },

    onFileAdded(file) {
      this.emit('fileAdded')
      let fileVerifyRes = this.fileVerify(file)
      if (!fileVerifyRes) {
        return false
      }
      // 文件校验成功，开始上传
      this.panelShow = true
      // 将额外的参数赋值到每个文件上，以不同文件使用不同params的需求
      file.params = this.customParams

      // 计算MD5
      this.computeMD5(file).then((result) => {
        const { md5, file } = result
        file.md5 = md5
        file.allChunksLength = file.chunks.length // 将所有chuanks记录下来
        this.beforeUploadCheck(file).then((res) => {
          // res 已上传的分片集合
          if (res.length > 0) {
            if (res.length === file.allChunksLength) {
              // 文件分片都上传完毕，等待合并
              this.mergeFile(file)
            } else {
              // 设置file新的chunks（删除掉已经上传的chunk）
              let newChunks = []
              file.chunks.forEach((i) => {
                if (!res.includes(i.offset + 1)) {
                  newChunks.push(i)
                }
              })
              file.chunks = newChunks
              this.startUpload({ md5: md5, file })
            }
          } else {
            // 没有上传过，直接上传
            this.startUpload({ md5: md5, file })
          }
        })
      })
    },

    // 上传前文件的check检查
    beforeUploadCheck(file) {
      return new Promise((resolve, reject) => {
        this.$api.file
          .check({
            fileName: file.name,
            fileMd5: file.md5,
            dir: file.params.path,
            project_id: file.params.project_id
          })
          .then((res) => {
            console.log('check finish')
            if (res.message == 'Success') {
              const { resultCode, chunks, fileName } = res.data
              if (resultCode == 0) {
                // 文件已经存在
                console.log('文件已存在')
                this.uploader.removeFile(file)
                this.emit('fileHasExist', { file, filePath: fileName })
                reject('has exist')
              } else {
                if (resultCode == -1) {
                  // 该文件没有上传过
                  this.skip = false
                  resolve([])
                } else {
                  // 文件分片已经存在
                  resolve(chunks.map((i) => parseInt(i))) // 需要跳过上传的分片列表
                }
              }
            } else {
              reject('error')
            }
          })
          .catch((err) => {
            reject('error')
          })
      })
    },

    // md5计算完毕，开始上传
    startUpload({ md5, file }) {
      file.md5 = md5
      file.resume()
      this.statusRemove(file.id)
    },

    /**
     * 计算md5值，以实现断点续传及秒传
     * @param file
     * @returns Promise
     */
    computeMD5(file) {
      let fileReader = new FileReader()
      let time = new Date().getTime()
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice
      let currentChunk = 0
      const chunkSize = 10 * 1024 * 1000
      let chunks = Math.ceil(file.size / chunkSize)
      let spark = new SparkMD5.ArrayBuffer()

      // 文件状态设为"计算MD5"
      this.statusSet(file.id, 'md5')
      file.pause()

      loadNext()

      return new Promise((resolve, reject) => {
        fileReader.onload = (e) => {
          spark.append(e.target.result)

          if (currentChunk < chunks) {
            currentChunk++
            loadNext()

            // 实时展示MD5的计算进度
            this.$nextTick(() => {
              const dom = document.querySelector(`.custom-status-${file.id}`)
              if (dom) {
                const md5ProgressText =
                  '校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%'
                dom.innerText = md5ProgressText
              }
             
            })
          } else {
            let md5 = spark.end()

            // md5计算完毕
            resolve({ md5, file })

            console.log(
              `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
                file.size
              } 用时：${new Date().getTime() - time} ms`
            )
          }
        }

        fileReader.onerror = function () {
          this.error(`文件${file.name}读取出错，请检查该文件`)
          file.cancel()
          reject()
        }
      })

      function loadNext() {
        let start = currentChunk * chunkSize
        let end = start + chunkSize >= file.size ? file.size : start + chunkSize

        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
      }
    },

    onFileSuccess(rootFile, file, response, chunk) {
      let res = JSON.parse(response)
      console.log('onFileSuccess', res, rootFile, file, chunk)

      // 再次检查,如果返回的chunk数+1(后端从零开始)和总chunk数一致,说明成功
      this.$api.file
        .check({
          fileName: file.name,
          fileMd5: file.md5,
          dir: file.params.path,
          project_id: file.params.project_id
        })
        .then((res) => {
          if (res.message === 'Success') {
            const { resultCode } = res.data
            if (resultCode == file.allChunksLength) {
              this.mergeFile(file)
            } else {
              // 上传失败
              this.error('上传失败，请重试')
              this.statusSet(file.id, 'failed')
            }
          } else {
            // 上传失败
            this.error('上传失败，请重试')
            this.statusSet(file.id, 'failed')
          }
        })
    },

    // 合并文件
    mergeFile(file) {
      this.statusSet(file.id, 'merging') // 文件状态设为“合并中”
      this.$api.file
        .merge({
          fileMd5: file.md5,
          dir: file.params.path,
          project_id: file.params.project_id,
          fileName: file.name
        })
        .then((mergeRes) => {
          if (mergeRes.message === 'Success') {
            // 文件合并成功
            this.statusSet(file.id, 'success') // 文件状态设置为上传成功
            // this.finishAddFile()//自动执行完成按钮功能
            this.emit('fileSuccess', {
              file: file,
              filePath: mergeRes.data.filepath
            }) // 文件路径返回
            this.statusRemove(file.id)
          } else {
            this.error('文件合并失败，请重试')
            this.statusSet(file.id, 'mergeFailed')
          }
        })
        .catch((err) => {
          this.error('文件合并失败，请重试')
          this.statusSet(file.id, 'mergeFailed')
        })
    },

    onFileProgress(rootFile, file, chunk) {
      console.log(
        `上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${
          chunk.endByte / 1024 / 1024
        }`
      )
    },

    onFileError(rootFile, file, response, chunk) {
      this.error(response)
    },

    close() {
      this.uploader.cancel()
      this.panelShow = false
    },

    /**
     * 新增的自定义的状态: 'md5'、'merging'、'transcoding'、'failed'
     * @param id
     * @param status
     */
    statusSet(id, status) {
      let statusMap = {
        md5: {
          text: '校验MD5',
          bgc: '#fff'
        },
        merging: {
          text: '合并中',
          bgc: '#e2eeff'
        },
        transcoding: {
          text: '转码中',
          bgc: '#e2eeff'
        },
        failed: {
          text: '上传失败',
          bgc: '#e2eeff'
        },
        mergeFailed: {
          text: '合并文件失败',
          bgc: '#e2eeff'
        },
        success: {
          text: '上传成功',
          bgc: '#e2eeff'
        }
      }

      this.customStatus = status
      this.$nextTick(() => {
        const statusTag = document.createElement('p')
        statusTag.className = `custom-status-${id} custom-status`
        statusTag.innerText = statusMap[status].text
        statusTag.style.backgroundColor = statusMap[status].bgc

        const statusWrap = document.querySelector(
          `.file_${id} .uploader-file-status`
        )
        statusWrap.appendChild(statusTag)
      })
    },

    statusRemove(id) {
      this.customStatus = ''
      this.$nextTick(() => {
        const statusTag = document.querySelector(`.custom-status-${id}`)
        statusTag.remove()
      })
    },

    emit(e, data) {
      Bus.$emit(e, data)
      this.$emit(e, data)
    },

    error(msg) {
      this.$notify({
        title: '错误',
        message: msg,
        type: 'error',
        duration: 2000
      })
    }
  }
}
</script>

<style lang="scss">
#global-uploader {
  &:not(.global-uploader-single) {
    position: fixed;
    z-index: 2999;
    right: 15px;
    bottom: 15px;
    box-sizing: border-box;
  }

  .uploader-btn {
    background: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 15px;
    &:hover {
      background: var(--primary-color-opac);
    }
  }

  .uploader-app {
    width: 520px;
  }

  .file-panel {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 7px 7px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .file-title {
      display: flex;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      border-bottom: 1px solid #ddd;

      .operate {
        flex: 1;
        text-align: right;

        i {
          font-size: 18px;
        }
      }
    }

    .file-list {
      position: relative;
      height: 240px;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #fff;
      transition: all 0.3s;

      .file-item {
        background-color: #fff;
      }
    }

    &.collapse {
      .file-title {
        background-color: #e7ecf2;
      }
      .file-list {
        height: 0;
      }
    }
  }

  .no-file {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }

  .uploader-file {
    &.md5 {
      .uploader-file-resume {
        display: none;
      }
    }
  }

  .uploader-file-icon {
    &:before {
      content: '' !important;
    }

    &[icon='image'] {
      background: url(./images/image-icon.png);
    }
    &[icon='audio'] {
      background: url(./images/audio-icon.png);
      background-size: contain;
    }
    &[icon='video'] {
      background: url(./images/video-icon.png);
    }
    &[icon='document'] {
      background: url(./images/text-icon.png);
    }
    &[icon='unknown'] {
      background: url(./images/zip.png) no-repeat center;
      background-size: contain;
    }
  }

  .uploader-file-actions > span {
    margin-right: 6px;
  }

  .custom-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

/* 隐藏上传按钮 */
#global-uploader-btn {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.global-uploader-single {
  #global-uploader-btn {
    position: relative;
  }
}

.uploader-list {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 3000;
  width: 900px;
}
</style>
