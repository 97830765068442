<template>
  <div class="bg-white  overflow-hidden">
    <div class="text-37px font-bold text-center text-pri mt-23px">
      注册成功！
    </div>
    <div class="text-19px text-center text-gray-900 mt-15px">
      {{ userName }} 欢迎加入始智AI平台，你可以进一步完善个人资料。
    </div>
    <div class="flex mt-100px mx-181px">
      <div class="flex-1">
        <el-form ref="form" :model="form">
          <el-form-item label="头像">
            <div class="flex text-gray-500 items-center">
              <el-upload
                action=""
                accept=".jpg, .jpeg, .png, .gif"
                :before-upload="beforeUpload"
                :auto-upload="false"
                :on-change="fileChange"
                :show-file-list="false"
              >
                <div
                  class="w-107px h-107px flex justify-center items-center"
                  style="border: 1px dashed #e1dce9"
                >
                  <img
                    v-if="form.avatar"
                    :src="form.avatar"
                    class="w-107px h-107px"
                  />
                  <img
                    v-else
                    src="@/assets/images/login/upload.png"
                    class="w-32px h-32px"
                  />
                </div> </el-upload
              ><span class="ml-20px">上传更新头像</span>
            </div>
          </el-form-item>
          <el-form-item label="工作单位">
            <el-input v-model="form.work_unit" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="职位">
            <el-input v-model="form.position" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="毕业或就读院校">
            <el-input v-model="form.school" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="专业">
            <el-input v-model="form.major" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="学历">
            <el-select
              v-model="form.educationalBackground"
              placeholder="请选择"
            >
              <el-option
                v-for="item in $store.state.selectOptions.backgroundEdu"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex-1">
        <el-form ref="form" :model="form">
          <el-form-item label="入学年份">
            <el-input v-model="form.entryYear" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="form.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio
              v-model="form.sex"
              v-for="item in $store.state.selectOptions.sex"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-form-item>
          <el-form-item label="个人简介">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入"
              v-model="form.personalProfile"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="text-center mt-33px">
      <el-button
        class="w-453px h-53px"
        type="primary"
        @click="finish"
        :loading="loading"
        >完成</el-button
      >
    </div>
  </div>
</template>

<script>

export default {
  props: {
    username: {},
    phone: {}
  },
  data() {
    return {
      userName: '',
      form: {
        avatar: ''
      },
      loading: false
    }
  },
  created() {
    this.getUserName()
  },
  mounted() {},
  methods: {
    finish() {
      this.loading = true
      const {
        avatar, // 头像
        school, // 毕业院校
        major, // 专业
        educationalBackground: edu_grade, // 学历
        entryYear: enter_year, // 入学年份
        name: login_name, // 姓名
        sex, // 性别
        personalProfile: remark, // 简介
        work_unit, //公司
        position   //职位
      } = this.form
      this.$api.user
        .putInfoNoToken({
          phone: this.phone,
          avatar,
          school,
          major,
          edu_grade,
          enter_year,
          login_name,
          sex: sex?.toString(),
          remark,
          work_unit,
          position
        })
        .then((res) => {
          if (res.message === 'Success') {
            this.$message.success('保存成功，请登录')
            this.$emit('finish')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    fileChange(file) {
      if (file) {
        const data = new FormData()
        data.append('file', file.raw)
        this.$api.user.uploadAvatar(data).then((res) => {
          const { message } = res
          if (message === 'Success') {
            this.form.avatar = res.data
          }
        })
      }
    },
    beforeUpload(file) {
      const isPic = file.type.includes('image/')
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isPic) {
        this.$message.error('上传头像图片只能是图片格式!')
        return
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
        return
      }
      return true
    },
    getUserName() {
      const userInfo = this.$store.state.system.userInfo
      if (userInfo) {
        this.userName = userInfo.username
        return
      }
      this.userName = this.$props.username // 注册时填写的name
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep .el-form {
  .el-form-item__content {
    display: flex;
  }
  .el-form-item__label {
    width: 180px;
    padding-right: 20px;
    font-size: 19px;
    color: #000;
  }
}

</style>
