<template>
  <div class="bg-white h-650px overflow-hidden">
    <div class="text-37px font-bold text-center mt-23px">
      请完善你的账号信息
    </div>
    <div class="px-50px mt-35px">
      <el-form ref="form" :model="form" :rules="formRule">
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="telphone">
          <el-input
            v-model="form.telphone"
            placeholder="请输入手机号"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="loginPsd">
          <el-input
            v-model="form.loginPsd"
            placeholder="请输入登录密码"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPsd">
          <el-input
            v-model="form.confirmPsd"
            placeholder="请输入确认密码"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>

      <el-button
        class="w-full h-53px mt-48px"
        type="primary"
        @click="save"
        :loading="loading"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { valiUsername, valiPassword, valiMobilePhone } from '@/utils/validate'
import isEmail from "validator/lib/isEmail"

export default {
  data() {
    const usernameValidate = (rule, value, callback) => {
      const { status, message } = valiUsername(value)

      if (!status) {
        callback(message)
      } else {
        callback()
      }
    }

    const emailValidate = (rule, value, callback) => {
      const message = "非法邮箱格式"
      const status = isEmail(value)

      if (!status) {
        callback(message)
      } else {
        callback()
      }
    }

    const passwordValidate = (rule, value, callback) => {
      const { status, message } = valiPassword(value)
      if (!status) {
        callback(message)
      } else {
        callback()
      }
    }

    const mobilephoneValidate = (rule, value, callback) => {
      const { status, message } = valiMobilePhone(value)
      if (!status) {
        callback(message)
      } else {
        callback()
      }
    }

    const confirmPsdValite = (rule, value, callback) => {
      if (value !== this.form.loginPsd) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      form: {
        username: '',
        email: '',
        telphone: '',
        loginPsd: ''
      },

      formRule: {
        username: [
          { required: true, validator: usernameValidate, trigger: 'blur' }
        ],
        email: [{ required: true, validator: emailValidate, trigger: 'blur' }],
        loginPsd: [
          { required: true, validator: passwordValidate, trigger: 'blur' }
        ],
        confirmPsd: [
          { required: true, validator: confirmPsdValite, trigger: 'blur' }
        ],
        telphone: [{required: false, validator: mobilephoneValidate, trigger: 'blur'}]
      }
    }
  },
  created() {
    this.form.telphone = this.$store.state.system.userBaseInfo.phone
  },
  mounted() {},
  methods: {
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const { username: user_name, loginPsd: password, email } = this.form
          this.loading = true
          this.$api.user
            .putInfoNeedToken({
              user_name,
              password,
              email
            })
            .then((res) => {

              if (res.message === 'Success') {
                this.$message.success('保存成功')
                const userid = this.$store.state.system.userInfo.userid
                this.$store.dispatch('system/setUserInfo', {
                  userid,
                  username: user_name
                })

                this.$api.user.saveUserBaseInfo() // 更新信息
                this.$emit('save')
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep {
  .el-input {
    height: 53px;
  }
  .el-form-item__label {
    height: 53px;
    line-height: 53px;
  }
}

</style>
